import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { Navigate, useNavigate } from 'react-router'
import { GrAccessibility, GrAdd, GrAnchor, GrAttraction, GrBike, GrBook, GrBug, GrCar, GrCircleInformation, GrClearOption, GrCloud, GrCluster, GrCodeSandbox, GrGremlin, GrGrow, GrPaint, GrRestaurant, GrRobot, GrAddCircle, GrNext, GrFormPreviousLink, GrUserAdd, GrSettingsOption, GrUserWorker, GrShare, GrSplit, GrUpgrade, GrAlert } from 'react-icons/gr'
import { useState } from 'preact/hooks'
import Button from '../Button'
import UserContext from '../../context/UserContext'
import { useQuery, useSubscription } from '@apollo/client'
import { GET_CAMPAIGN, GET_CAMPAIGNS } from './queries'
import { useParams } from 'react-router-dom'
import Shimmer from '../Shimmer'

import AdminMenu from './adminmenu'
import UserMenu from './usermenu'
import { Card } from '../Card'
import ReactTooltip from 'react-tooltip'
import { Suspense } from 'preact/compat'
import Empty from '../Empty'
import CampaignOverview from '../CampaignOverview'
import { CHARACTER_ADDED } from './subscriptions'

const Container = styled.div`
    position: relative;
    height: 100%;

    & h1 {
        display: flex;
        margin: 0 1rem 0 1rem;
        padding-bottom: 0;
        font-weight: 400;
        font-size: 1rem;
        
        align-items: center;
        border-bottom: .5px solid rgba(0, 0, 0, .1);

        ${props => props.collapsed ? `
            margin: 0;

            & > div {
                display: flex;
                flex: 1;
                justify-content: center;

            }

            & > span {
                flex: unset !important;
                margin-left: 2px;
            }
        ` : ''}
    }

    ${props => props.collapsed ? `
        & .adnd-campaign-menu > li {
            margin-left: 0.625rem;
        }
    ` : ''}

    & ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-top: .5rem;
         
        & li {
            position: relative;
            overflow: hidden;
            cursor: pointer;
            margin: 0;
            padding: .5rem 0;
            padding-left: .625rem;
            display: flex;
            align-items: center;
            margin-bottom: .5rem;
            width: 100%;
            

            & > div:first-of-type {

                & svg path {
                    stroke: #fff;
                }
            }

            & .next {
                opacity: 0;
                transition: opacity .25s;
                position: relative;
                right: .5rem;
            }

            

            &:after {
                position: absolute;
                content: "";
                display: block;
                background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 7%, rgba(255,255,255,0.8015581232492998) 26%, rgba(255,255,255,1) 49%, rgba(255,255,255,1) 77%);
                right: -2rem; 
                top: -.25rem;
                transition: transform .25s;
                transform: translateX(calc(100% - 2rem));
                width: calc(100% + 4rem);
                height: calc(100% + 1rem);
                z-index: -1;
            }

            &:hover {
                & .next {
                    opacity: 1;
                }    

                &:after {
                    transform: translateX(0%);
                }
            }
        }
    }

    & .dark-icon {
        width: .8rem;

        path {
            stroke: var(--neutral-500) !important;
        }
    }
`

const SlideContainer = styled.div`
    position: absolute;
    left: calc(-100% * ${props => props.offset});
    width: 100%;
    height: 100%;
    display: flex;
    transition: left .25s;
`

const Slide = styled.div`
    width: 100%;
    min-width: 100%;
    flex-shrink: 0;
`

const Campaign = styled.li`
    position: relative;
    flex: 1;
`

const CampaignColor = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 6px;
    background: var(--${props => props.color});
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .5rem;
`

const EmptyContainer = styled.div`
    margin-top: 1rem;

    & button {
        font-weight: 400;
        font-size: 1rem;
        padding: 1rem 1rem;
        margin-bottom: -0.75rem;
    }
`

const ErrorMessage = styled.div`
    text-align: center;
    padding-top: 1rem;    

    & p {
        display: ${props => props.collapsed ? 'none' : 'block'};
    }

    & svg {
        font-size: ${props => props.collapsed ? '1.5rem' : '4rem'};
        & path { stroke: red; }
    }
    
    & button {
        margin: 0 auto;
    }
`

const CampaignIcons = {
    "paint": <GrPaint />,
    "restaurant": <GrRestaurant />,
    "gremlin": <GrGremlin />,
    "attraction": <GrAttraction />,
    "bike": <GrBike />,
    "codeSandbox": <GrCodeSandbox />,
    "bug": <GrBug />,
    "clearOption": <GrClearOption />,
    "cloud": <GrCloud />,
    "cluster": <GrCluster />,
    "grow": <GrGrow />,
    "robot": <GrRobot />
}

export default function CampaignList({collapsed, openLoginModal, setCampaignPanes, selectedCampaign, setSelectedCampaign, ...props}) {

    const nav = useNavigate()

    const [slideOffset, setSlideOffset] = useState(0)
    const [selectedCampaignData, setSelectedCampaignData] = useState()

    const { loading, error, data, subscribeToMore, refetch } = useQuery(GET_CAMPAIGNS)

    const closeTab = tabId => {
        setCampaignPanes(prev => {
            let panes = [...prev]
            const existsInPaneIndex = panes.findIndex(pane => pane.tabs.find(t => t.id === tabId))

            if (existsInPaneIndex !== -1) {
                panes[existsInPaneIndex].tabs = panes[existsInPaneIndex].tabs.filter(t => t.id !== tabId)
                if (panes[existsInPaneIndex].tabs.length === 0) {
                    panes = panes.filter((pane, index) => index !== existsInPaneIndex)
                } else {
                    panes[existsInPaneIndex].selectedTab = panes[existsInPaneIndex].tabs[0].id
                }
            }
            
            return panes
        })
    }

    const openInNewTab = (tabId, title, content, onSplit, onClose) => {
        setCampaignPanes(prev => {
            const panes = [...prev]

            const existsInPaneIndex = panes.findIndex(pane => pane.tabs.find(t => t.id === tabId))
            if (existsInPaneIndex === -1) {
                panes[0] = {
                    selectedTab: tabId,
                    tabs: [...(panes[0] && panes[0].tabs ? panes[0].tabs : []), {
                        id: tabId,
                        title,
                        content: <Suspense fallback={<img src="/assets/img/loader.svg" width="320" height="240" />}>{content}</Suspense>,
                        onSplit: () => openInNewPane(tabId, title, content),
                        onClose: () => closeTab(tabId)
                    }]
                }
            } else {
                panes[existsInPaneIndex] = {
                    ...panes[existsInPaneIndex],
                    selectedTab: tabId
                }
            }
            
            return panes
        })
    }

    const openInNewPane = (tabId, title, content) => {
        setCampaignPanes(prev => {
            const panes = [...prev]
            
            const existsInPaneIndex = panes.findIndex(pane => pane.tabs.find(t => t.id === tabId))

            if (existsInPaneIndex === -1) {
                panes[panes.length] = {
                    selectedTab: tabId,
                    tabs: [{
                        id: tabId,
                        title,
                        content: <Suspense fallback={<img src="/assets/img/loader.svg" width="320" height="240" />}>{content}</Suspense>,
                        onSplit: () => openInNewPane(tabId, title, content),
                        onClose: () => closeTab(tabId)
                    }]
                }
            } else {
                if (panes[existsInPaneIndex].tabs.length > 1) {
                    
                    const tab = panes[existsInPaneIndex].tabs.find(t => t.id === tabId)
                    panes[existsInPaneIndex].tabs = panes[existsInPaneIndex].tabs.filter(t => t.id !== tabId)
                    panes[existsInPaneIndex].selectedTab = panes[existsInPaneIndex].tabs[0].id
                    panes[panes.length] = {
                        selectedTab: tabId,
                        tabs: [tab]
                    }
                } else {
                    panes[existsInPaneIndex] = {
                        ...panes[existsInPaneIndex],
                        selectedTab: tabId
                    }
                }
            }
            
            return panes
        })
    } 

    const openCampaign = campaign => {

        if (selectedCampaign && selectedCampaign.id === campaign.id) {
            setSlideOffset(1)
            return
        }

        setSlideOffset(1)
        setSelectedCampaign(campaign)
        
        setCampaignPanes([ {
            selectedTab: 'campaign-' + campaign.id,
            tabs: [{
                id: 'campaign-' + campaign.id,
                title: "Campaign Overview",
                close: false,
                content: <CampaignOverview campaign={campaign} subscribeToMore={subscribeToMore} />
            }]
        }])
    }

    const createCampaign = (user) => {
        if (user) {
            nav('/new-campaign')
        } else {
            openLoginModal('/new-campaign')
        }
    }

    useEffect(() => {
        if (!selectedCampaign && data && data.campaigns && window.location.pathname.includes('campaign/'))  {
            
            const start = window.location.pathname.indexOf('campaign/') + 9
            let end = window.location.pathname.indexOf('/', start)
            if (end === -1) { end = window.location.pathname.length } 

            const slug = window.location.pathname.slice(start, end)
            const campaign = data.campaigns.find(c => c.slug === slug)

            if (campaign) {
                openCampaign(campaign)
            }
        }
    }, [window.location, data])

    useEffect(() => {
        ReactTooltip.rebuild()
    }, [collapsed])

    return <Container collapsed={collapsed}>
        <UserContext.Consumer>
            {({user}) => <SlideContainer offset={slideOffset}>
                <Slide>
                    <h1>{collapsed ? '' : 'Campaigns'}
                        <div>
                            <Button style={{padding: '1rem .5rem'}} small onClick={() => createCampaign(user)}>
                                <GrAddCircle />
                            </Button>
                        </div>
                    </h1>
                    {loading ? 
                        <ul>
                            <Campaign>
                                <div style={{display: 'flex', gap: '.5rem'}}>
                                    <Shimmer wrapped width="2rem" height="2rem" />
                                    <Shimmer width="8rem" height="1rem" />
                                </div>
                            </Campaign>
                            <Campaign>
                                <div style={{display: 'flex', gap: '.5rem'}}>
                                    <Shimmer wrapped width="2rem" height="2rem" />
                                    <Shimmer width="10rem" height="1rem" />
                                </div>
                            </Campaign>
                            <Campaign>
                                <div style={{display: 'flex', gap: '.5rem'}}>
                                    <Shimmer wrapped width="2rem" height="2rem" />
                                    <Shimmer width="5rem" height="1rem" />
                                </div>
                            </Campaign>
                        </ul>
                    :
                        (error && error.message && error.message.toLowerCase() === "unauthenticated") || (data && data.campaigns && data.campaigns.length === 0) ?
                            !collapsed ? <EmptyContainer><Empty icon={<GrUpgrade />} label="to get started"><Button small onClick={() => createCampaign(user)}>Create a Campaign</Button></Empty></EmptyContainer> : null
                        : (error ? <ErrorMessage collapsed={collapsed}><GrAlert color="red" /><p>Failed to load campaigns</p><Button tiny={collapsed} outline onClick={() => refetch({})}>⟳ {!collapsed ? 'Retry' : ''}</Button></ErrorMessage> : 
                            <ul>
                                {data.campaigns.map(campaign => <Campaign data-for="adnd-campaign-menu-tooltip" data-tip={!collapsed ? null : campaign.name} onClick={e => { 
                                    openCampaign(campaign)
                                    nav('/campaign/' + campaign.slug)
                                }}>
                                    <div style={{display: 'flex', justifyContent: 'space-between', flex: 1, alignItems: 'center'}}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <CampaignColor color={campaign.color}>{CampaignIcons[campaign.icon]}</CampaignColor>
                                            {collapsed ? null : campaign.name}
                                        </div>

                                        <GrNext className='next' style={{position: 'relative', top: '.05rem'}} />
                                    </div>
                                </Campaign>)}
                            </ul>
                        )
                    }
                </Slide>

                <Slide>
                <h1><Button style={{padding: '1rem .5rem'}} small><GrFormPreviousLink onClick={e => setSlideOffset(0)} /></Button>{collapsed ? '' : 'Back'}
                        <div>
                            {slideOffset === 0 && 
                                <Button small onClick={() => {
                                    if (user) {
                                        nav('/new-campaign')
                                    } else {
                                        openLoginModal('/new-campaign')
                                    }
                                }}>
                                </Button>
                            }
                        </div>
                    </h1>

                    {user && selectedCampaign && user.id == selectedCampaign.dmId ? 
                        <AdminMenu collapsed={collapsed} campaignId={selectedCampaign.id} openInNewPane={openInNewPane} openInNewTab={openInNewTab} /> 
                    : 
                        selectedCampaign ? <UserMenu user={user} collapsed={collapsed} campaignId={selectedCampaign.id} openInNewPane={openInNewPane} openInNewTab={openInNewTab} /> : null
                    }
                    
                </Slide>
            </SlideContainer>}
        </UserContext.Consumer>
        
        <ReactTooltip 
            id="adnd-campaign-menu-tooltip"
            place="right"
            effect='solid'
            multiline={true}
        />
    </Container>
}