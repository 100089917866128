const { gql } = require("@apollo/client");

const GET_CAMPAIGNS = gql`
    query Campaigns {
        campaigns {
            id
            name
            slug
            color 
            icon
            dmId
            dm {
                id
                name
                color 
                icon 
                avatar
            }
            players {
                id
                name
                color 
                icon 
                avatar
            }
            characterfactories {
                id
                userId 
                slug 
                step
            }
            characterFactoryDefaults {
                abilityScoreGenerationStrategy
                hitPointGenerationStrategy
                spellSelectionStrategy
            }
            characters {
                id
                userId
                name
                race
                class
                str
                dex
                con
                int
                wis
                cha   
            }
        }
    }
`

const GET_CAMPAIGN = gql`
    query Campaign($campaignId: ID!) {
        campaign(campaignId: $campaignId) {
            id
            name
            slug
            dm {
                id
                name
                color 
                icon 
                avatar  
            }
            players {
                id
                name
                color 
                icon 
                avatar
            }
            characters {
                id
                userId
                name
                race 
                class 
                str 
                dex 
                con 
                int 
                wis 
                cha
            }
            characterfactories {
                id
                step
                slug
                userId
            }
            characterFactoryDefaults {
                abilityScoreGenerationStrategy
                hitPointGenerationStrategy
                spellSelectionStrategy
            }
        }
    }
`

export {
    GET_CAMPAIGNS,
    GET_CAMPAIGN
}