import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Navigate, useNavigate } from 'react-router'
import Modal from '../Modal'
import { Body, Card, Subtitle, Title } from '../Card'
import Checkbox from '../Checkbox'
import { EClass, EClassGroup, EClassGroupLabel, GetClassGroupLabel } from '../../enums/EClass'
import Magic from '../../datasets/Magic'
import Tag from '../Tag'
import Collapsible from '../Collapsible'
import ContextMenu from '../ContextMenu'
import SpellSelector from '../SpellSelector'
import { EMagicSchool } from '../../enums/EMagicSchool'
import MenuButton from '../MenuButton'
import Button from '../Button'
import Input from '../Input'

const Container = styled.div`

`

const CardContainer = styled.div`
    display: flex;

    & > div {
        margin: .5rem;
        flex: 1 1 100%;
        cursor: pointer;
    }

    & > div:first-of-type {
        margin-left: -.5rem;
    }

    & > div:last-of-type {
        margin-right: -.5rem;
    }
`

const Spellbook = styled.div`
    width: 100%;
`

const SpellList = styled.div`

`

const Class = styled.div`

`

const Spells = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const ModalFooter = styled.footer`
    display: flex;
    justify-content: space-between;
`

function CharacterList(props) {

    const nav = useNavigate()
    const [factoryModalVisible, setFactoryModalVisible] = React.useState(false)
    const [selectedAbilityScoreGenerationStrategy, setSelectedAbilityScoreGenerationStrategy] = React.useState("3d6sequential")
    const [selectedHitPointGenerationStrategy, setSelectedHitPointGenerationStrategy] = React.useState("hitdie")
    const [selectedSpellSelectionStrategy, setSelectedSpellSelectionStrategy] = React.useState("fixed")

    const [fixedClassSpells, setFixedClassSpells] = React.useState({
        [EClass.CLERIC]:      [{school: "divination", id: "detectmagic"}, {school: "divination", id: "detectevil"}, {school: "abjuration", id: "protectionfromevil"}, {school: "conjuration", id:"bless"}, {school: "alteration", id: "createwater"}, {school: "necromancy", id: "curelightwounds"}],
        [EClass.DRUID]:       [{school: "divination", id: "detectmagic"}, {school: "enchantment", id: "animalfriendship"}, {school: "enchantment", id: "command"}, {school: "alteration", id:"endurecoldendureheat"}, {school: "alteration", id: "invisibilitytoanimals"}, {school: "alteration", id: "purifyfooddrink"}],
        [EClass.MAGE]:        [{school: "divination", id: "readmagic"}, {school: "divination", id: "detectmagic"}, {school: "general", id: "cantrip"}, {school: "evocation", id:"magicmissile"}],
        [EClass.ABJURER]:     [{school: "divination", id: "readmagic"}, {school: "divination", id: "detectmagic"}, {school: "general", id: "cantrip"}, {school: "abjuration", id:"alarm"}],
        [EClass.CONJURER]:    [{school: "divination", id: "readmagic"}, {school: "divination", id: "detectmagic"}, {school: "general", id: "cantrip"}, {school: "conjuration", id:"findfamiliar"}],
        [EClass.DIVINER]:     [{school: "divination", id: "readmagic"}, {school: "divination", id: "detectmagic"}, {school: "general", id: "cantrip"}, {school: "divination", id:"identify"}],
        [EClass.ENCHANTER]:   [{school: "divination", id: "readmagic"}, {school: "divination", id: "detectmagic"}, {school: "general", id: "cantrip"}, {school: "enchantment", id:"sleep"}],
        [EClass.ILLUSIONIST]: [{school: "divination", id: "readmagic"}, {school: "divination", id: "detectmagic"}, {school: "general", id: "cantrip"}, {school: "illusion", id:"spook"}],
        [EClass.INVOKER]:     [{school: "divination", id: "readmagic"}, {school: "divination", id: "detectmagic"}, {school: "general", id: "cantrip"}, {school: "evocation", id:"walloffog"}],
        [EClass.NECROMANCER]: [{school: "divination", id: "readmagic"}, {school: "divination", id: "detectmagic"}, {school: "general", id: "cantrip"}, {school: "necromancy", id:"chilltouch"}],
        [EClass.TRANSMUTER]:  [{school: "divination", id: "readmagic"}, {school: "divination", id: "detectmagic"}, {school: "general", id: "cantrip"}, {school: "alteration", id:"enlarge"}],
    })
    const [playerBlacklistedSpells, setPlayerBlacklistedSpells] = React.useState({
        [EClass.CLERIC]:      [],
        [EClass.DRUID]:       [],
        [EClass.MAGE]:        [],
        [EClass.ABJURER]:     [],
        [EClass.CONJURER]:    [],
        [EClass.DIVINER]:     [],
        [EClass.ENCHANTER]:   [],
        [EClass.ILLUSIONIST]: [],
        [EClass.INVOKER]:     [],
        [EClass.NECROMANCER]: [],
        [EClass.TRANSMUTER]:  [],
    })
    const [randomBlacklistedSpells, setRandomBlacklistedSpells] = React.useState({
        [EClass.CLERIC]:      [],
        [EClass.DRUID]:       [],
        [EClass.MAGE]:        [],
        [EClass.ABJURER]:     [],
        [EClass.CONJURER]:    [],
        [EClass.DIVINER]:     [],
        [EClass.ENCHANTER]:   [],
        [EClass.ILLUSIONIST]: [],
        [EClass.INVOKER]:     [],
        [EClass.NECROMANCER]: [],
        [EClass.TRANSMUTER]:  [],
    })

    const [_3d6SequentialBestOf, set3d6SequentialBestOf] = React.useState(0)
    const [_3d6SequentialRerolls, set3d6SequentialRerolls] = React.useState(0)
    const [_3d6PoolBestOf, set3d6PoolBestOf] = React.useState(0)
    const [_3d6PoolRerolls, set3d6PoolRerolls] = React.useState(0)
    const [pointPoolPoints, setPointPoolPoints] = React.useState(60)

    const [hitDieBestOf, setHitDieBestOf] = React.useState(0)
    const [playerInitialWizardSpells, setPlayerInitialWizardSpells] = React.useState(4)
    const [playerInitialPriestSpells, setPlayerInitialPriestSpells] = React.useState(6)
    const [randomInitialWizardSpells, setRandomInitialWizardSpells] = React.useState(4)
    const [randomInitialPriestSpells, setRandomInitialPriestSpells] = React.useState(6)

    const [spellSelectorVisible, setSpellSelectorVisible] = React.useState(false)
    const [spellSelectorTop, setSpellSelectorTop] = React.useState(0)
    const [spellSelectorLeft, setSpellSelectorLeft] = React.useState(0)
    const [spellSelectorFilter, setSpellSelectorFilter] = React.useState([])
    const [spellSelectorSchoolBlacklist, setSpellSelectorSchoolBlacklist] = React.useState([])
    const [spellSelectorActiveClass, setSpellSelectorActiveClass] = React.useState()

    const [blacklistSpellSelectorVisible, setBlacklistSpellSelectorVisible] = React.useState(false)
    const [blacklistSpellSelectorTop, setBlacklistSpellSelectorTop] = React.useState(0)
    const [blacklistSpellSelectorLeft, setBlacklistSpellSelectorLeft] = React.useState(0)
    const [blacklistSpellSelectorFilter, setBlacklistSpellSelectorFilter] = React.useState([])
    const [blacklistSpellSelectorSchoolBlacklist, setBlacklistSpellSelectorSchoolBlacklist] = React.useState([])
    const [blacklistSpellSelectorActiveClass, setBlacklistSpellSelectorActiveClass] = React.useState()

    const [randomBlacklistSpellSelectorVisible, setRandomBlacklistSpellSelectorVisible] = React.useState(false)
    const [randomBlacklistSpellSelectorTop, setRandomBlacklistSpellSelectorTop] = React.useState(0)
    const [randomBlacklistSpellSelectorLeft, setRandomBlacklistSpellSelectorLeft] = React.useState(0)
    const [randomBlacklistSpellSelectorFilter, setRandomBlacklistSpellSelectorFilter] = React.useState([])
    const [randomBlacklistSpellSelectorSchoolBlacklist, setRandomBlacklistSpellSelectorSchoolBlacklist] = React.useState([])
    const [randomBlacklistSpellSelectorActiveClass, setRandomBlacklistSpellSelectorActiveClass] = React.useState()

    const [fixedSpellListExpanded, setFixedSpellListExpanded] = React.useState(true)
    const [playerSpellListExpanded, setPlayerSpellListExpanded] = React.useState(true)
    const [randomSpellListExpanded, setRandomSpellListExpanded] = React.useState(true)

    const [playerSpellTotalExpanded, setPlayerSpellTotalExpanded] = React.useState(true)
    const [randomSpellTotalExpanded, setRandomSpellTotalExpanded] = React.useState(true)

    const [factories, setFactories] = React.useState([])

    useEffect(() => {
        const getFactories = async () => {
            /*
            let f = await window.localforage.getItem("factories")
            if (!f) {
                f = {}
            }

            const fA = Object.keys(f).reduce((fa, key) => {
                fa.push(f[key])
                return fa
            }, [])
            setFactories(fA)
            */
        }

        getFactories()
    }, [])

    const MakeCharacterFactory = async payload => {
        /*
        const resp = await API.graphql(graphqlOperation(makeCharacterFactory, {input: payload}))
    
        const factory = resp.data.makeCharacterFactory
        const factories = await window.localforage.getItem("factories")
        await window.localforage.setItem("factories", {
            ...factories,
            [factory.id]: {...factory, startedAt: Date.now()}
        })
    
        if (selectedAbilityScoreGenerationStrategy == "3d6sequential") {
            nav('/rollAbilityScores', { state: { factoryId: factory.id } })
        } else if (selectedAbilityScoreGenerationStrategy == "3d6pool") {
            nav('/assignAbilityScoresFromPool', { state: { factoryId: factory.id } })
        } else if (selectedAbilityScoreGenerationStrategy == 'pointpool') {
            nav('/assignAbilityScoresFromPoints', { state: { factoryId: factory.id } })
        }
        */
    }

    const ToggleCharacterFactoryModal = async event => {
        setFactoryModalVisible(!factoryModalVisible)
    }

    const toggleSpellSelector = async (event, c) => {
        event.preventDefault()
        event.stopPropagation()

        const group = GetClassGroupLabel(c)
        let blacklist = []
        switch(c) {
            case EClass.ABJURER: blacklist = [EMagicSchool.ALTERATION]; break;
            case EClass.NECROMANCER: blacklist = [EMagicSchool.ILLUSION]; break;
            case EClass.INVOKER: blacklist = [EMagicSchool.ENCHANTMENT]; break;
            case EClass.DIVINER: blacklist = [EMagicSchool.CONJURATION]; break;
            case EClass.ILLUSIONIST: blacklist = [EMagicSchool.NECROMANCY]; break;
            case EClass.ENCHANTER: blacklist = [EMagicSchool.EVOCATION]; break;
            case EClass.CONJURER: blacklist = [EMagicSchool.DIVINATION]; break;
        }

        setSpellSelectorActiveClass(c)
        setSpellSelectorTop(event.clientY)
        setSpellSelectorLeft(event.clientX)
        setSpellSelectorFilter([group])
        setSpellSelectorSchoolBlacklist(blacklist)
        setSpellSelectorVisible(true)
        
    }

    const toggleBlacklistedSpellSelector = async (event, c) => {
        event.preventDefault()
        event.stopPropagation()

        const group = GetClassGroupLabel(c)
        let blacklist = []
        switch(c) {
            case EClass.ABJURER: blacklist = [EMagicSchool.ALTERATION]; break;
            case EClass.NECROMANCER: blacklist = [EMagicSchool.ILLUSION]; break;
            case EClass.INVOKER: blacklist = [EMagicSchool.ENCHANTMENT]; break;
            case EClass.DIVINER: blacklist = [EMagicSchool.CONJURATION]; break;
            case EClass.ILLUSIONIST: blacklist = [EMagicSchool.NECROMANCY]; break;
            case EClass.ENCHANTER: blacklist = [EMagicSchool.EVOCATION]; break;
            case EClass.CONJURER: blacklist = [EMagicSchool.DIVINATION]; break;
        }

        setBlacklistSpellSelectorActiveClass(c)
        setBlacklistSpellSelectorTop(event.clientY)
        setBlacklistSpellSelectorLeft(event.clientX)
        setBlacklistSpellSelectorFilter([group])
        setBlacklistSpellSelectorSchoolBlacklist(blacklist)
        setBlacklistSpellSelectorVisible(true)
    }

    const toggleRandomBlacklistedSpellSelector = async (event, c) => {
        event.preventDefault()
        event.stopPropagation()

        const group = GetClassGroupLabel(c)
        let blacklist = []
        switch(c) {
            case EClass.ABJURER: blacklist = [EMagicSchool.ALTERATION]; break;
            case EClass.NECROMANCER: blacklist = [EMagicSchool.ILLUSION]; break;
            case EClass.INVOKER: blacklist = [EMagicSchool.ENCHANTMENT]; break;
            case EClass.DIVINER: blacklist = [EMagicSchool.CONJURATION]; break;
            case EClass.ILLUSIONIST: blacklist = [EMagicSchool.NECROMANCY]; break;
            case EClass.ENCHANTER: blacklist = [EMagicSchool.EVOCATION]; break;
            case EClass.CONJURER: blacklist = [EMagicSchool.DIVINATION]; break;
        }

        setRandomBlacklistSpellSelectorActiveClass(c)
        setRandomBlacklistSpellSelectorTop(event.clientY)
        setRandomBlacklistSpellSelectorLeft(event.clientX)
        setRandomBlacklistSpellSelectorFilter([group])
        setRandomBlacklistSpellSelectorSchoolBlacklist(blacklist)
        setRandomBlacklistSpellSelectorVisible(true)
    }

    const dismissSpellSelector = async event => {
        event.preventDefault()
        event.stopPropagation()

        setSpellSelectorVisible(false)
    }

    const dismissBlacklistSpellSelector = async event => {
        event.preventDefault()
        event.stopPropagation()

        setBlacklistSpellSelectorVisible(false)
    }

    const dismissRandomBlacklistSpellSelector = async event => {
        event.preventDefault()
        event.stopPropagation()

        setRandomBlacklistSpellSelectorVisible(false)
    }

    const removeFixedClassSpell = (group, spellId) => {
        const spells = {...fixedClassSpells}
        const index = spells[group].findIndex(s => s.id == spellId)
        if (index != -1) {
            spells[group].splice(index, 1)
            setFixedClassSpells(spells)
        }
    }

    const removePlayerBlacklistedSpell = (group, spellId) => {
        const spells = {...playerBlacklistedSpells}
        const index = spells[group].findIndex(s => s.id == spellId)
        if (index != -1) {
            spells[group].splice(index, 1)
            setPlayerBlacklistedSpells(spells)
        }
    }

    const removeRandomBlacklistedSpell = (group, spellId) => {
        const spells = {...randomBlacklistedSpells}
        const index = spells[group].findIndex(s => s.id == spellId)
        if (index != -1) {
            spells[group].splice(index, 1)
            setRandomBlacklistedSpells(spells)
        }
    }

    const onFixedSpellSelection = (spell, school) => {
        const id = spell.id
        const spells = {...fixedClassSpells}
        spells[spellSelectorActiveClass].push({school, id})
        setFixedClassSpells(spells)
    }

    const onPlayerBlacklistSpellSelection = (spell, school) => {
        const id = spell.id
        const spells = {...playerBlacklistedSpells}
        spells[blacklistSpellSelectorActiveClass].push({school, id})
        setPlayerBlacklistedSpells(spells)
    }

    const onRandomBlacklistSpellSelection = (spell, school) => {
        const id = spell.id
        const spells = {...randomBlacklistedSpells}
        spells[randomBlacklistSpellSelectorActiveClass].push({school, id})
        setRandomBlacklistedSpells(spells)
    }

    const generateMakeFactoryPayload = () => {
        const params = {
            abilityScoreGeneration: {
                strategy: selectedAbilityScoreGenerationStrategy,
                bestOf: 0,
                rerolls: 0,
                points: 0
            },
            hitPointGeneration: {
                strategy: selectedHitPointGenerationStrategy,
                bestOf: 0
            },
            spellSelection: {
                strategy: selectedSpellSelectionStrategy,
                fixedSpells: fixedClassSpells,
                blacklistedSpells: null,
            }
        }

        if (selectedAbilityScoreGenerationStrategy == "3d6sequential") {
            params.abilityScoreGeneration.bestOf = _3d6SequentialBestOf
            params.abilityScoreGeneration.rerolls = _3d6SequentialRerolls
        } else if (selectedAbilityScoreGenerationStrategy == "3d6pool") {
            params.abilityScoreGeneration.bestOf = _3d6PoolBestOf
            params.abilityScoreGeneration.rerolls = _3d6PoolRerolls
        } else if (selectedAbilityScoreGenerationStrategy == "pointpool") {
            params.abilityScoreGeneration.points = pointPoolPoints
        }

        if (selectedHitPointGenerationStrategy == "hitdie") {
            params.hitPointGeneration.bestOf = hitDieBestOf
        }

        if (selectedSpellSelectionStrategy == "player") {
            params.spellSelection.blacklistedSpells = playerBlacklistedSpells
        } else if (selectedSpellSelectionStrategy == "random") {
            params.spellSelection.blacklistedSpells = randomBlacklistedSpells
        }

        return params
    }

    const beginSingleCharacterCreation = event => {
        MakeCharacterFactory(generateMakeFactoryPayload())
    }

    const selectFactory = factory => {

        let dest = factory.step
        if (dest == 'rollAbilityScores') {
            if (factory.abilityScoreGenerator.class.includes('PointPoolStrategy')) {
                dest = "assignAbilityScoresFromPoints"
            } else if (factory.abilityScoreGenerator.class.includes('PoolStrategy')) {
                dest = "assignAbilityScoresFromPool"
            }
        }

        nav('/' + dest, { state: { factoryId: factory.id } })
    }

    const selectCharacter = factoryId => {
        props.onCharacterSelection && props.onCharacterSelection(factoryId)
    }

    return (
        <Container>
            <h1>Characters</h1>
            <ul>
                {factories.filter(f => f.step == "done").map(f => <li onClick={e => selectCharacter(f.id)}><div>{(new Date(f.startedAt).toUTCString())}</div></li>)}
            </ul>

            <h1>Incomplete</h1>
            <ul>
                {factories.filter(f => f.step != "done").map(f => <li onClick={e => selectFactory(f)}>
                    <div>{(new Date(f.startedAt).toUTCString())}</div>
                    <div>Step: {f.step.split(/(?=[A-Z])/).map(s => s.toLowerCase()).join(' ')}</div>
                    
                </li>)}
            </ul>

            <footer> 
                <button onClick={ToggleCharacterFactoryModal}>New Character</button> 
            </footer>

            <Modal visible={factoryModalVisible} onDismiss={ToggleCharacterFactoryModal}>
                <main>
                    <h1>Set Character Creation Parameters</h1>
                    <h2>Ability Score Generation</h2>
                    <CardContainer>
                        <Card active={selectedAbilityScoreGenerationStrategy == "3d6sequential"} onClick={e => setSelectedAbilityScoreGenerationStrategy("3d6sequential")}>
                            <Checkbox id="chk3d6sequential" style={{position: 'absolute', right: '2rem'}} value={selectedAbilityScoreGenerationStrategy == "3d6sequential"} />
                            <Title>3d6 Sequential</Title>
                            <Body>Each ability score is rolled for in turn using 3d6. Scores <b>can not</b> be redistributed or reallocated.</Body>
                            <footer style={{display: selectedAbilityScoreGenerationStrategy == "3d6sequential" ? 'flex' : 'none', flexWrap: 'wrap'}}>
                                <div style={{display: 'flex'}}>
                                    <span>Best of:</span>
                                    <span><Input type="number" min={0} max={4} value={_3d6SequentialBestOf} onChange={e => set3d6SequentialBestOf(e.target.value)}></Input></span>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <span>Rerolls:</span>
                                    <span><Input type="number" min={0} max={99} value={_3d6SequentialRerolls} onChange={e => set3d6SequentialRerolls(e.target.value)}></Input></span>
                                </div>
                            </footer>
                        </Card>

                        <Card active={selectedAbilityScoreGenerationStrategy == "3d6pool"} onClick={e => setSelectedAbilityScoreGenerationStrategy("3d6pool")}>
                            <Checkbox id="chk3d6pool" style={{position: 'absolute', right: '2rem'}} value={selectedAbilityScoreGenerationStrategy == "3d6pool"} />
                            <Title>3d6 Pool</Title>
                            <Body>Six scores are rolled using 3d6 that you must then allocate to your character ability scores.</Body>
                            <footer style={{display: selectedAbilityScoreGenerationStrategy == "3d6pool" ? 'flex' : 'none', flexWrap: 'wrap'}}>
                                <div style={{display: 'flex'}}>
                                    <span>Best of:</span>
                                    <span><Input type="number" min={0} max={4} value={_3d6PoolBestOf} onChange={e => set3d6PoolBestOf(e.target.value)}></Input></span>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <span>Rerolls:</span>
                                    <span><Input type="number" min={0} max={99} value={_3d6PoolRerolls} onChange={e => set3d6PoolRerolls(e.target.value)}></Input></span>
                                </div>
                            </footer>
                        </Card>

                        <Card active={selectedAbilityScoreGenerationStrategy == "pointpool"} onClick={e => setSelectedAbilityScoreGenerationStrategy("pointpool")}>
                            <Checkbox id="chkpointpool" style={{position: 'absolute', right: '2rem'}} value={selectedAbilityScoreGenerationStrategy == "pointpool"} />
                            <Title>Point Pool</Title>
                            <Body>You are given a fixed number of points to allocate to your character ability scores freely.</Body>
                            <footer style={{display: selectedAbilityScoreGenerationStrategy == "pointpool" ? 'flex' : 'none', flexWrap: 'wrap'}}>
                                <div style={{display: 'flex'}}>
                                    <span>Points:</span>
                                    <span><Input type="number" min={0} max={100} value={pointPoolPoints} onChange={e => setPointPoolPoints(e.target.value)}></Input></span>
                                </div>
                            </footer>
                        </Card>
                    </CardContainer>

                    <h2>Hit Point Generation</h2>
                    <CardContainer>
                        <Card active={selectedHitPointGenerationStrategy == "hitdie"} onClick={e => setSelectedHitPointGenerationStrategy("hitdie")}>
                            <Checkbox id="chkhitdie" style={{position: 'absolute', right: '2rem'}} value={selectedHitPointGenerationStrategy == "hitdie"} />
                            <Title>Hit Die</Title>
                            <Body>Your class hit die is rolled to determine your starting hit points.</Body>
                            <footer style={{display: selectedHitPointGenerationStrategy == "hitdie" ? 'flex' : 'none', flexWrap: 'wrap'}}>
                                <div style={{display: 'flex'}}>
                                    <span>Best of:</span>
                                    <span><Input type="number" min={0} max={4} value={hitDieBestOf} onChange={e => setHitDieBestOf(e.target.value)}></Input></span>
                                </div>
                            </footer>
                        </Card>

                        <Card active={selectedHitPointGenerationStrategy == "max"} onClick={e => setSelectedHitPointGenerationStrategy("max")}>
                            <Checkbox id="chkmax" style={{position: 'absolute', right: '2rem'}} value={selectedHitPointGenerationStrategy == "max"} />
                            <Title>Max Hit Points</Title>
                            <Body>The max value of your class hit die is used as your starting hit points.</Body>
                        </Card>
                    </CardContainer>

                    <h2>Spell Selection</h2>
                    <CardContainer>
                        <Card active={selectedSpellSelectionStrategy == "fixed"} onClick={e => setSelectedSpellSelectionStrategy("fixed")}>
                            <Checkbox id="chkfixed" style={{position: 'absolute', right: '2rem'}} value={selectedSpellSelectionStrategy == "fixed"} />
                            <Title>Fixed Based on Class</Title>
                            <Body>Each class starts with a specific set of spells in their spellbook.</Body>
                            <footer style={{display: selectedSpellSelectionStrategy == "fixed" ? 'flex' : 'none', flexWrap: 'wrap'}}>
                                <Collapsible title="Configure Spell Assignments" collapsed={fixedSpellListExpanded} onClick={e => setFixedSpellListExpanded(!fixedSpellListExpanded)}>
                                    <Spellbook>
                                        <SpellList>
                                            {Object.keys(fixedClassSpells).map(key => <>
                                                <Class>{key} <button onClick={e => toggleSpellSelector(e, key)}>+</button></Class>
                                                
                                                <Spells>
                                                    {fixedClassSpells[key].map(s => {
                                                        const group = GetClassGroupLabel(key)
                                                        const spell = Magic[group][s.school].find(_s => _s.id == s.id)
                                                        return <Tag onRemove={e => {e.stopPropagation(); removeFixedClassSpell(key, spell.id);}}>{spell.name}</Tag>
                                                    })}
                                                </Spells>
                                            </>)}
                                            <Class></Class>
                                            <Spells>

                                            </Spells>
                                        </SpellList>
                                    </Spellbook>
                                </Collapsible>
                                
                            </footer>
                        </Card>

                        <Card active={selectedSpellSelectionStrategy == "player"} onClick={e => setSelectedSpellSelectionStrategy("player")}>
                            <Checkbox id="chkplayer" style={{position: 'absolute', right: '2rem'}} value={selectedSpellSelectionStrategy == "player"} />
                            <Title>Players Choice</Title>
                            <Body>Players get to choose their spellbooks starting spells.</Body>

                            <footer style={{display: selectedSpellSelectionStrategy == "player" ? 'flex' : 'none', flexWrap: 'wrap'}}>
                            
                                <Collapsible title="Configure Blacklisted Spells" collapsed={playerSpellListExpanded} onClick={e => setPlayerSpellListExpanded(!playerSpellListExpanded)}>
                                    <Spellbook>
                                        <SpellList>
                                            {Object.keys(playerBlacklistedSpells).map(key => <>
                                                <Class>{key} <button onClick={e => toggleBlacklistedSpellSelector(e, key)}>+</button></Class>
                                                
                                                <Spells>
                                                    {playerBlacklistedSpells[key].map(s => {
                                                        const group = GetClassGroupLabel(key)
                                                        const spell = Magic[group][s.school].find(_s => _s.id == s.id)
                                                        return <Tag onRemove={e => {e.stopPropagation(); removePlayerBlacklistedSpell(key, spell.id);}}>{spell.name}</Tag>
                                                    })}
                                                </Spells>
                                            </>)}
                                        </SpellList>
                                    </Spellbook>
                                </Collapsible>
                            </footer>
                        </Card>

                        <Card active={selectedSpellSelectionStrategy == "random"} onClick={e => setSelectedSpellSelectionStrategy("random")}>
                            <Checkbox id="chkrandom" style={{position: 'absolute', right: '2rem'}} value={selectedSpellSelectionStrategy == "random"} />
                            <Title>Random</Title>
                            <Body>Players have a chance for any first level spells in their spellbook based on a dice roll vs their intelligence and Chance to Learn Spell value.</Body>

                            <footer style={{display: selectedSpellSelectionStrategy == "random" ? 'flex' : 'none', flexWrap: 'wrap'}}>
                                <Collapsible title="Configure Blacklisted Spells" collapsed={randomSpellListExpanded} onClick={e => setRandomSpellListExpanded(!randomSpellListExpanded)}>
                                    <Spellbook>
                                        <SpellList>
                                            {Object.keys(randomBlacklistedSpells).map(key => <>
                                                <Class>{key} <button onClick={e => toggleRandomBlacklistedSpellSelector(e, key)}>+</button></Class>
                                                
                                                <Spells>
                                                    {randomBlacklistedSpells[key].map(s => {
                                                        const group = GetClassGroupLabel(key)
                                                        const spell = Magic[group][s.school].find(_s => _s.id == s.id)
                                                        return <Tag onRemove={e => {e.stopPropagation(); removeRandomBlacklistedSpell(key, spell.id);}}>{spell.name}</Tag>
                                                    })}
                                                </Spells>
                                            </>)}
                                        </SpellList>
                                    </Spellbook>
                                </Collapsible>
                            </footer>
                        </Card>
                    </CardContainer>
                </main>
                
                <ModalFooter>
                    <Button>Cancel</Button>
                    <MenuButton onClick={beginSingleCharacterCreation} items={[{
                        label: "Begin Multiple Characters",
                        onClick: () => {}
                    }, {
                        label: "Email link(s) to begin character creation",
                        onClick: () => {}
                    }]}>Begin Single Character Creation</MenuButton>
                </ModalFooter>

            </Modal>

            <ContextMenu visible={spellSelectorVisible} top={spellSelectorTop} left={spellSelectorLeft} onDismiss={dismissSpellSelector}>
                <SpellSelector levelLimit={1} filter={spellSelectorFilter} schoolBlacklist={spellSelectorSchoolBlacklist} onSelect={onFixedSpellSelection} />
            </ContextMenu>

            <ContextMenu visible={blacklistSpellSelectorVisible} top={blacklistSpellSelectorTop} left={blacklistSpellSelectorLeft} onDismiss={dismissBlacklistSpellSelector}>
                <SpellSelector levelLimit={1} filter={blacklistSpellSelectorFilter} schoolBlacklist={blacklistSpellSelectorSchoolBlacklist} onSelect={onPlayerBlacklistSpellSelection} />
            </ContextMenu>

            <ContextMenu visible={randomBlacklistSpellSelectorVisible} top={randomBlacklistSpellSelectorTop} left={randomBlacklistSpellSelectorLeft} onDismiss={dismissRandomBlacklistSpellSelector}>
                <SpellSelector levelLimit={1} filter={randomBlacklistSpellSelectorFilter} schoolBlacklist={randomBlacklistSpellSelectorSchoolBlacklist} onSelect={onRandomBlacklistSpellSelection} />
            </ContextMenu>
        </Container>
    )
}

export default CharacterList