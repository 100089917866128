import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

const SearchInput = styled.div`
    display: block;
    position: relative;

    border: 1px solid rgba(0,0,0,.8);
    padding: .5rem;
    text-align: right;

    &:after {
        display: block;
        content: "🔍";
        position: absolute;
        top: .5rem;
        left: .5rem;

    }
`

export default props => {
    const inpNode = React.useRef(null)
    const onChange = event => {
        props.onChange(inpNode.current.innerText)
    }

    useEffect(() => {
        inpNode.current.addEventListener("input", onChange)
    }, [])

    return <SearchInput ref={inpNode} contentEditable />
}