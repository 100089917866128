import React from 'react'
import styled from 'styled-components'

const Collapsible = styled.div`
    width: 100%;
`

const Title = styled.div`
    position: relative;
    width: 100%;

    &:after {
        position: absolute;
        display: block;
        content: "⌵";
        top: 0;
        right: 0;
        transform: rotate(${props => props.collapsed ? '0' : '180deg'});
    }

    &:hover {
        font-weight: 600;
        cursor: pointer;
    }
`

const Content = styled.div`
    display: ${props => props.collapsed ? 'none' : 'block'};
`

export default ({style = {}, ...props}) => {
    return <Collapsible onClick={props.onClick}>
        <Title style={style} collapsed={props.collapsed}>{props.title}</Title>
        <Content collapsed={props.collapsed}>{props.children}</Content>
    </Collapsible>
}