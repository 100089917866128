import styled, {keyframes} from "styled-components"

const ShimmerAnimation = keyframes`

    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
`

const Shimmer = styled.div`
    background: rgba(0, 0, 0, .12);
    position: relative; 
    overflow: hidden;

    width: ${props => props.width};
    height: ${props => props.height};

    ${props => props.circle ? `border-radius: 50%;` : ''}

    &:before {
        content: '';
        position: absolute;
        background: linear-gradient(
            90deg,
            rgba(255,255,255,0) 0%,
            rgba(255,255,255,0.4) 50%,
            rgba(255,255,255,0) 100%
          );
        height: 100%;
        width: 100%;
        z-index: 1;
        animation: ${ShimmerAnimation} 1s infinite;
    }
`

export default Shimmer