import React from 'react'
import styled from 'styled-components'

const CardContainer = styled.div`
    
    border-radius: 6px;
    padding: 1rem;
    position: relative; 
    background: white;
`

const Title = styled.h1`
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    font-weight: 200;

    ${props => props.active ? `
        font-weight: 600;
    ` : ''}
`

const Subtitle = styled.h2`

`

const Body = styled.p`
    margin-top: -.5rem;
    line-height: 1.5;
    flex: 1;
`


function Card(props) {

   return (
        <CardContainer active={props.active} className={`adnd-card ${props.active ? 'active' : ''} ${props.className ? props.className : ''}`} onClick={props.onClick} style={props.style ? props.style : {}} >
            {props.children}
        </CardContainer>
    )
}

export { 
    Card,
    Title,
    Subtitle,
    Body
}