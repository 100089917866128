import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import ContextMenu from '../ContextMenu'

const Button = styled.button`
    
`

const MenuTrigger = styled.div`
    height: 100%;
    width: 1rem;
    border: 1px solid black;
`

const MenuItem = styled.div``

export default props => {

    const [contextMenuVisible, setContextMenuVisible] = React.useState(false)
    const [top, setTop] = React.useState(0)
    const [left, setLeft] = React.useState(0)

    const triggerRef = useRef(null)
    const menuRef = useRef(null)
    useEffect(() => {
        triggerRef.current.addEventListener('click', e => {
            setContextMenuVisible(true)
        })
        
        setTop(triggerRef.current.offsetTop - 106)
        setLeft(triggerRef.current.offsetLeft - 192)
    }, [triggerRef.current, menuRef.current])

    return <>
        <div style={{display: 'flex'}}>
            <MenuTrigger ref={triggerRef}>+</MenuTrigger>
            <Button onClick={props.onClick}>{props.children}</Button>
        </div>
        <ContextMenu ref={menuRef} visible={contextMenuVisible} top={top} left={left} onDismiss={e => setContextMenuVisible(false)}>
            {props.items ? props.items.map(item => <MenuItem onClick={item.onClick}>{item.label}</MenuItem>) : null}
        </ContextMenu>
    </>
}