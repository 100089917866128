import styled from "styled-components"
import { GrAccessibility, GrAdd, GrAnchor, GrAttraction, GrBike, GrBook, GrBug, GrCar, GrCircleInformation, GrClearOption, GrCloud, GrCluster, GrCodeSandbox, GrGremlin, GrGrow, GrPaint, GrRestaurant, GrRobot, GrAddCircle, GrNext, GrFormPreviousLink, GrUserAdd, GrSettingsOption, GrUserWorker, GrShare, GrSplit } from 'react-icons/gr'

const CampaignIcons = {
    "paint": <GrPaint />,
    "restaurant": <GrRestaurant />,
    "gremlin": <GrGremlin />,
    "attraction": <GrAttraction />,
    "bike": <GrBike />,
    "codeSandbox": <GrCodeSandbox />,
    "bug": <GrBug />,
    "clearOption": <GrClearOption />,
    "cloud": <GrCloud />,
    "cluster": <GrCluster />,
    "grow": <GrGrow />,
    "robot": <GrRobot />
}

const CampaignColor = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 6px;
    background: var(--${props => props.color});
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .5rem;

    & svg path {
        stroke: white;
    }
`

export default function CampaignAvatar({campaign, style, ...props}) {

    return <CampaignColor color={campaign.color} style={style || {}}>{CampaignIcons[campaign.icon]}</CampaignColor>
}