import React from 'react'
import styled from 'styled-components'
import Modal from '../Modal'
import Input from '../Input'
import Checkbox from '../Checkbox'
import Button from '../Button'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { REGISTER } from './mutations'
import { useState } from 'preact/hooks'
import LabeledInput from '../LabeledInput'
import UserContext from '../../context/UserContext'

const Login = styled.div`
   & > .adnd-modal {
        max-width: 640px;
   }
`


export default function RegisterModal({visible, setVisible, openLoginModal}) {

    const [register, { loading, error }] = useMutation(REGISTER)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [pswd, setPswd] = useState("")

    return <Login>
        
        <UserContext.Consumer>
            {({user, setUser}) => 
                <Modal visible={visible} onDismiss={e => setVisible(false)}>
                    <h1 style="padding-bottom: 1.5rem; margin-top: -1rem; display: flex; gap: .5rem; align-items: center;">
                        Register
                        <span style="font-size: 1rem; font-weight: 200; position: relative; top: .25rem;">Already have an account? <span style="cursor: pointer; font-weight: 400; color: var(--primary-color);" onClick={openLoginModal}>Click here to log in</span></span>
                    </h1>
                    <LabeledInput label="Name" value={name} setValue={setName} />
                    <LabeledInput label="Email" type="email" value={email} setValue={setEmail} />
                    <LabeledInput label="Password" type="password" value={pswd} setValue={setPswd} />
                    
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div></div>
                        
                        <Button primary large onClick={() => !loading && register({
                            variables: {
                                name,
                                email,
                                pswd
                            },
                            onError: (error) => {
                                console.error('Registration error', error)
                            },
                            onCompleted: ({register}) => {
                                if (register && register.token) {
                                    const userRecord = {
                                        ...register,
                                        name,
                                        email,
                                        lastLogin: new Date(),
                                    }
                                    setUser(userRecord)
                                                
                                    window.localStorage.setItem('authedUser', JSON.stringify(userRecord)) 
                                    window.localStorage.setItem('rememberMe', true)  

                                    setVisible(false)
                                    //window.location = window.location
                                }
                            }
                        })}>{loading ? "..." : "Register"}</Button>
                    </div>
                </Modal>
            }
        </UserContext.Consumer>
    </Login>
}