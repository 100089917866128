import { gql } from "@apollo/client";

const PLAYER_SUBSCRIPTION = gql`
    subscription OnPlayerAdded($campaignId: ID!) {
        playerAdded(campaignId: $campaignId) {
            id
            name
            icon
            color 
            avatar
        }
    }
`

const CHARACTER_ADDED = gql`
    subscription OnCharacterAdded($campaignId: ID!) {
        characterAdded(campaignId: $campaignId) {
            id
            name
            class 
            race
            userId
            str
            dex
            con
            int
            wis
            cha   
        }
    }
`

const PLAYER_UPDATED = gql`
    subscription OnPlayerUpdate($campaignId: ID!) {
        playerUpdated(campaignId: $campaignId) {
            id
            name
            icon
            color 
            avatar
        }
    }
`

export {
    PLAYER_SUBSCRIPTION,
    PLAYER_UPDATED,
    CHARACTER_ADDED
}