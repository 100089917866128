import { InMemoryCache } from '@apollo/client'

const cache = new InMemoryCache({
    typePolicies: {
        CharacterFactory: {
          fields: {
            otherProficiencySlots : { 
              merge(existing, incoming, { mergeObjects }) {
                return incoming;
              },
            },
          },
        },
        Query: {
          fields: {
            campaignMessages: {
              keyArgs: false,
              merge(existing = [], incoming) {

                const messages = Object.values([...incoming, ...existing].reduce((msgs, msg) => {
                  if (!msgs.hasOwnProperty(msg.__ref)) {
                    msgs[msg.__ref] = msg
                  }

                  return msgs
                }, {}))

                messages.sort((a, b) => {
                  if (a.createdAt > b.createdAt) {
                      return 1
                  } else if (a.createdAt < b.createdAt) {
                      return -1
                  }
      
                  return 0
              })

                return messages
              }
            },
            playerMessages: {
              keyArgs: false,
              merge(existing = [], incoming) {
                
                
                const messages = Object.values([...incoming, ...existing].reduce((msgs, msg) => {
                  if (!msgs.hasOwnProperty(msg.__ref)) {
                    msgs[msg.__ref] = msg
                  }

                  return msgs
                }, {}))

                messages.sort((a, b) => {
                  if (a.createdAt > b.createdAt) {
                      return 1
                  } else if (a.createdAt < b.createdAt) {
                      return -1
                  }
      
                  return 0
              })

                return messages
              }
            }
          }
        }
      },
})

export default cache