import styled from "styled-components"
import { GiAirMan, GiAlienBug, GiAmericanFootballBall, GiAmericanFootballHelmet, GiBackup, GiBlindfold, GiBrain, GiBrutalHelm, GiBrute, GiBulletBill, GiCapeArmor, GiCat, GiCentaur, GiCeremonialMask, GiChameleonGlyph, GiCleopatra, GiCowled, GiCrackedMask, GiCrestedHelmet, GiDwarfFace } from 'react-icons/gi'

const Avatar = styled.div`
    width: ${props => props.height};
    height: ${props => props.height};
    border-radius: 50%;
    border: .25rem solid var(--${props => props.color});
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    transition: width .25s, height .25s;
    flex-shrink: 0;

    ${props => props.avatar ? `
        background: url(${props.avatar});
    ` : null}

    & svg {
        font-size: 3rem;
    }

    & svg > path {
        stroke: white;
        fill: var(--${props => props.color});
        width: ${props => props.height};
        height: ${props => props.height};
    }
`

const Icons = {
    alien: <GiAlienBug />,
    airman: <GiAirMan />,
    blindfold: <GiBlindfold />,
    helm: <GiBrutalHelm />,
    brute: <GiBrute />,
    bulletbill: <GiBulletBill />,
    brain: <GiBrain />,
    capearmor: <GiCapeArmor />,
    cat: <GiCat />,
    centaur: <GiCentaur />,
    mask: <GiCeremonialMask />,
    glyph: <GiChameleonGlyph />,
    cleo: <GiCleopatra />,
    cowl: <GiCowled />,
    crackedmask: <GiCrackedMask />,
    crestedhelm: <GiCrestedHelmet />,
    dwarf: <GiDwarfFace />,
    party: <GiBackup />
}

export default function PlayerAvatar({icon, color, avatar, width, height, style = {}, ...props}) {

    return <Avatar 
        color={color} 
        avatar={avatar}
        width={width ? width : '2rem'}
        height={height ? height : '2rem'}
        style={style}
    >{icon ? Icons[icon] : null}</Avatar>
}