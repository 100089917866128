import React from 'react'
import styled from 'styled-components'
import Magic from '../../datasets/Magic'
import Collapsible from '../Collapsible'
import SearchInput from '../SearchInput'

const SpellSelector = styled.div`

`

export default props => {

    const [spellFilter, setSpellFilter] = React.useState("")
    const [wizardSpellsExpanded, setWizardSpellsExpanded] = React.useState(false)
    const [priestSpellsExpanded, setPriestSpellsExpanded] = React.useState(false)
    const [wizardSchoolsExpanded, setWizardSchoolsExpanded] = React.useState(Object.keys(Magic.wizard).reduce((schools, school) => {
        schools[school] = true
        return schools
    }, {}))
    const [priestSchoolsExpanded, setPriestSchoolsExpanded] = React.useState(Object.keys(Magic.priest).reduce((schools, school) => {
        schools[school] = true
        return schools
    }, {}))

    return <SpellSelector {...props}>
        <SearchInput onChange={v => setSpellFilter(v)} />

        {Object.keys(Magic).filter(c => props.filter ? props.filter.includes(c) : true).map(category => 
            <Collapsible collapsed={category == 'wizard' ? wizardSpellsExpanded : priestSpellsExpanded} title={category} onClick={event => category == "wizard" ? setWizardSpellsExpanded(!wizardSpellsExpanded) : setPriestSpellsExpanded(!priestSpellsExpanded)}>
                {Object.keys(Magic[category]).filter(s => props.schoolBlacklist ? !props.schoolBlacklist.includes(s) : true).map(school => 
                    <Collapsible collapsed={category == 'wizard' ? wizardSchoolsExpanded[school] : priestSchoolsExpanded[school]} title={school} onClick={event => {
                        event.preventDefault()
                        event.stopPropagation()

                        const schools = category == "wizard" ? wizardSchoolsExpanded : priestSchoolsExpanded
                        const setter = category == "wizard" ? setWizardSchoolsExpanded : setPriestSchoolsExpanded
                        const updated = {
                            ...schools,
                            [school]: !schools[school]
                        }
                        setter(updated)
                    }}>
                        {Magic[category][school].filter(spell => props.levelLimit ? spell.level <= props.levelLimit : true).filter(spell => spell.name.includes(spellFilter)).map(spell => 
                            <div onClick={event => {
                                event.preventDefault()
                                event.stopPropagation()

                                props.onSelect(spell, school, category)
                            }}>{spell.name}</div>
                        )}
                    </Collapsible>
                )}
            </Collapsible>
        )}
    </SpellSelector>
}