import { useQuery } from "@apollo/client";
import { lazy } from "preact/compat";
import { useEffect, useState } from "preact/hooks";
import Scrollbars from "react-custom-scrollbars-2";
import { GrSplit } from "react-icons/gr";
import styled from "styled-components";
import UserContext from "../../context/UserContext";
import Button from "../Button";
import CharacterAvatar from "../CharacterAvatar";
import CharacterSheet from "../CharacterSheet";
import Empty from "../Empty";
import Shimmer from "../Shimmer";
import { GET_CAMPAIGN } from "./queries";

const NewCharacter = lazy(() => import('../../routes/newcharacter'))

const Container = styled.div`

`

const EmptyContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const Flex = styled.div`
    display: flex;
`

export default function UserMenu({campaignId, openInNewPane, openInNewTab, user, collapsed, ...props}) {

    const [characters, setCharacters] = useState([])
    const [party, setParty] = useState([])

    const {loading, error, data: { campaign } = {data: {campaign: {}}}} = useQuery(GET_CAMPAIGN, {
        variables: {
            campaignId
        }
    })

    useEffect(() => {
        if (!campaign) { return }

        const characters = campaign.characters.reduce((characters, character) => {
            if (character.userId == user.id) {
                characters.characters.push(character)
            } else {
                characters.party.push(character)
            }

            return characters
        }, {characters: [], party: []})

        for(const factory of campaign.characterfactories) {
            if (factory.userId == user.id) {
                characters.characters.push({
                    ...factory,
                    pending: true
                })
            }
        }

        setCharacters(characters.characters)
        setParty(characters.party)

    }, [campaign])

    return <Container style={{height: '100%'}}>
        <UserContext.Consumer>
            {({user}) => {
                    return <>
                        <section style={{height: '100%'}}>
                        <h1>Your Characters</h1>
                        {loading ? <ul>
                                <li style={{display: 'flex', gap: '.5rem'}}>
                                    <Flex style={{gap: '.5rem'}}>
                                        <Shimmer circle width="2rem" height="2rem" />
                                        <Shimmer width="8rem" height="1rem" />
                                    </Flex>
                                </li>

                                <li style={{display: 'flex', gap: '.5rem'}}>
                                    <Flex style={{gap: '.5rem'}}>
                                        <Shimmer circle width="2rem" height="2rem" />
                                        <Shimmer width="4rem" height="1rem" />
                                    </Flex>
                                </li>
                            </ul> 
                        : null}
                        <Scrollbars style={{height: '100%'}} renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}>
                            {characters.length > 0 ? <ul className="adnd-scroll-list">
                                    {characters.map(character => 
                                    <li 
                                        data-for="adnd-campaign-menu-tooltip" 
                                        data-tip={collapsed ? character.name + "<br />" + character.race + " " + character.class : null} 
                                        style={{display: 'flex', gap: '.5rem'}} 
                                        onClick={e => character.pending ? 
                                              openInNewTab("character-factory-" + character.id, "New Character", <NewCharacter factorySlug={character.slug} />, e => e.stopPropagation(), e => e.stopPropagation())    
                                            : openInNewTab("character-" + character.id, character.name, <CharacterSheet characterId={character.id} />, e => e.stopPropagation(), e => e.stopPropagation())
                                        }
                                    >
                                        {character.pending ? 
                                            <>
                                                <CharacterAvatar characterClass="pending" characterRace="pending" />
                                                {!collapsed ? 'Pending' : ''}
                                                <br />
                                                {!collapsed ? 'Step: ' + character.step : ''}
                                            </>
                                        : 
                                            <>
                                                <CharacterAvatar characterClass={character["class"]} characterRace={character.race} />
                                                {!collapsed ? character.name : ''}

                                                <div style={{display: 'flex', flex: 1, justifyContent: 'space-between'}}>
                                                    <div></div>
                                                    <Button 
                                                        style={{margin: '-.25rem .25rem -.25rem 0'}}
                                                        onClick={e => {
                                                            e.stopPropagation()
                                                            openInNewPane('character-' + character.id, character.name, <CharacterSheet characterId={character.id} />)
                                                        }}
                                                    ><GrSplit className='dark-icon' /></Button>
                                                </div>
                                            </>
                                        }
                                        
                                    </li>)}
                            </ul> : null}
                        </Scrollbars>
                        {!loading && !error && characters.length === 0 ? <EmptyContainer><Empty label="No character sheets" /></EmptyContainer> : null}
                        </section>
                    </>
                }
            }
        </UserContext.Consumer>
        
        

    </Container>
}