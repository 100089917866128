import { gql } from "@apollo/client";

const GET_CHARACTER = gql`
    query Character($characterId: ID!) {
        character(characterId: $characterId) {
            name
            player {
                name
            }
            religion
            userId
            origin
            race
            class
            alignment
            str
            dex
            con
            int
            wis
            cha
            hitAdjustment
            dmgAdjustment
            weightAllowance
            maxPress
            openDoors
            bendBars
            surpriseAdjustment
            missileAdjustment
            defenseAdjustment
            hpAdjustment
            systemShock
            resurrectSurvival
            poisonSave
            regen
            addProficiencies
            intSpellImmunity
            magicalDefenseAdjustment
            bonusSpells
            spellFailure
            wisSpellImmunity
            maxHenchmen
            loyaltyBase
            reactionAdjustment
            totalLanguages
            maxSpellLevel
            learnSpellChance
            illusionImmunity
            maxSpellsPerLevel
            baseMovement
            exceptionalSTR
            thiefSkills {
                climbwall
                findtraps
                openlocks
                pickpocket
                detectnoise
                movesilently
                hideinshadows
                readlanguages
            }
            spells {
            paladin {
                general {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                alteration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                abjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                evocation {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                conjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                necromancy {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                divination {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                enchantment {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                illusion {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
            }
            cleric {
                general {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                alteration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                abjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                evocation {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                conjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                necromancy {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                divination {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                enchantment {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                illusion {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
            }
            druid {
                general {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                alteration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                abjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                evocation {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                conjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                necromancy {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                divination {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                enchantment {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                illusion {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
            }
            bard {
                general {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                alteration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                abjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                evocation {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                conjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                necromancy {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                divination {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                enchantment {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                illusion {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
            }
            mage {
                general {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                alteration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                abjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                evocation {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                conjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                necromancy {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                divination {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                enchantment {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                illusion {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
            }
            abjurer {
                general {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                alteration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                abjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                evocation {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                conjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                necromancy {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                divination {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                enchantment {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                illusion {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
            }
            conjurer {
                general {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                alteration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                abjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                evocation {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                conjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                necromancy {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                divination {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                enchantment {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                illusion {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
            }
            diviner {
                general {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                alteration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                abjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                evocation {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                conjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                necromancy {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                divination {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                enchantment {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                illusion {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
            }
            enchanter {
                general {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                alteration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                abjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                evocation {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                conjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                necromancy {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                divination {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                enchantment {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                illusion {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
            }
            illusionist {
                general {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                alteration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                abjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                evocation {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                conjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                necromancy {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                divination {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                enchantment {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                illusion {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
            }
            invoker {
                general {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                alteration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                abjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                evocation {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                conjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                necromancy {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                divination {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                enchantment {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                illusion {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
            }
            necromancer {
                general {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                alteration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                abjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                evocation {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                conjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                necromancy {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                divination {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                enchantment {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                illusion {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
            }
            transmuter {
                general {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                alteration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                abjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                evocation {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                conjuration {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                necromancy {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                divination {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                enchantment {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
                illusion {
                id
                name
                description
                range
                savingthrow
                aoe
                school
                level
                components
                castingtime
                duration
                }
            }
            }
        }
    }
`

export {
    GET_CHARACTER
}