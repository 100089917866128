import { useQuery } from '@apollo/client'
import React from 'react'
import ReactTooltip from 'react-tooltip'
import styled, {keyframes, css} from 'styled-components'
import Input from '../../components/Input'
import useElementSize from '../../hooks/useElementSize'
import useWindowSize from '../../hooks/useWindowSize'
import { Card } from '../Card'
import Shimmer from '../Shimmer'
import { GET_CAMPAIGN, GET_CHARACTER } from './queries'

const ShimmerAnimation = keyframes`
0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`

const Split = styled.div`
    display: flex;
    width: 100%;
`

const UInputNode = styled(Input)`
    border: 0;
    border-bottom: 2px solid black;
    width: 100%;
`

const UShimmerWrapper = styled.div`
  width: 100%;
  border-bottom: 2px solid black;
`

const UInput = ({value, placeholder, placeholderWidth, ...props}) => <>
    {placeholder ? <UShimmerWrapper><Shimmer style={{
        position: 'relative',
        top: '-0.18rem',
        left: '0.2rem'
    }} width={placeholderWidth ? placeholderWidth : '3rem'} height="1.5rem" /></UShimmerWrapper> : <UInputNode value={value} />}
</>

const Label = styled.span`
    font-weight: bold;
    font-size: 1.5em;
`

const Divider = styled.div`

    position: relative;
    padding-top: .4rem;
    padding-bottom: .4rem;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin-top: .75rem;
    margin-bottom: 1rem;

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: .33rem;
        width: 100%;    
        background: black;
    }
`

const Title = styled.div`
    font-size: 3rem;
    text-align: center;

    & > div {
        font-family: 'Fredericka the Great';
        white-space: nowrap;

        &:last-of-type {

            font-size: 2.5rem;
            margin-top: -2.5rem;

            & span {
                font-size: 4rem;
                font-family: 'Fredericka the Great';
                margin-left: -1rem;
                margin-right: -1rem;
            }
        }
    }
`

const Subtitle = styled.div`
    text-align: center;
`

const BInput = styled.div`
    boder: .5px solid rgba(0, 0, 0, .66);
    padding: .5rem;
    border: 3px solid black;
    width: 100%;
    
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: .1rem;

    &:before {
        animation: ${ShimmerAnimation} 1s infinite;
    }
    

    ${props => props.placeholder ? `
        background: rgba(0, 0, 0, .12);
        position: relative; 
        overflow: hidden;   

        width: ${props => props.width};
        height: ${props => props.height};

        ${props => props.circle ? `border-radius: 50%;` : ''}

        &:before {
            content: '';
            position: absolute;
            top: 0;
            background: linear-gradient(
                90deg,
                rgba(255,255,255,0) 0%,
                rgba(255,255,255,0.4) 50%,
                rgba(255,255,255,0) 100%
            );
            height: 100%;
            width: 100%;
            z-index: 1;
            
        }
    ` : `
        &:before {
            animation: none;
        }
        
    `}
`

const BLabel = styled.span`
    border: 3px solid black;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0 .33rem;
    position: relative;

    ${props => props.hp ? `
        height: 5rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    ` : ''}

    ${props => props.shield ? `

        border-bottom: 0;
        border-bottom-right-radius: 5px 25%;
        border-top-right-radius: 0.5rem 58%;
        border-top-left-radius: 0.5rem 58%;

        &:after {
            content: "";
            border: 3px solid black;
            border-top: 0;
            border-left: 0;
            width: 4.7rem;
            height: 4.7rem;
            position: absolute;
            bottom: -2rem;
            left: -3px;
            border-radius: 0 50%;
            transform: rotate(45deg);
        }
    ` : ''}
    
`

const Row = styled.div`
    display: flex;

    & > ${BLabel}, & > ${BInput} {
        margin-right: -3px;
    }
`

const StatRow = styled(Row)`
    & > h1 {
        margin: 0;
        padding: 0;
        margin-right: 1rem;
        min-width: 56px;

    }

    & > ${BLabel}:first-of-type {
        
    }

    & > ${BInput}:first-of-type {
        min-width: 4rem;
        max-width: 4rem;
        margin-right: 1rem;


    } 

    & h1 {
        line-height: 1.35;
    }
`

const MobileStatRow = styled.div`

`

const SavingThrows = styled.div`
    flex: .5;

    & > ${Row} > ${BLabel}:first-of-type {
        min-width: 7rem;
    }

    & > ${Row} {
        margin-top: -3px;
        min-height: 3rem;

        & > ${BLabel} {
            font-weight: 600;
            justify-content: center;
        }

        & > ${BInput} {
            min-width: 3rem;
            max-width: 3rem;
            width: 3rem;
        }

        & > ${BInput}:last-of-type {
            min-width: unset;
            max-width: unset;
            width: 100%;
        }
    }

    & > ${Row}:first-of-type {
        margin-bottom: 6px;
        & > ${BInput} {
            border: 0;
            text-align: center;
        }

        & > ${BLabel} {
            border: 0;
        }
    }
`

const Box = styled.div`
    border: 3px solid black;
`

const TargetAC = styled.div`

    margin-top: 1rem;
    margin-bottom: 1rem;

    & > ${Split}:last-of-type {
        margin-top: -3px;
    }

    & ${BLabel}, & ${BInput} {
        margin-right: -3px;
        padding: .25rem;
    }

    & ${BLabel} {
        font-weight: 600;
    }
`

const CombatMods = styled.div`

    & ${BLabel}, & ${BInput} {
        padding: .25rem;
        min-height: 1.8rem;
    }

    & ${BLabel} {
        font-weight: 600;
    }
`

const Profs = styled.div`

    & ${BLabel}, & ${BInput} {
        padding: .25rem;
        min-height: 1.8rem;
    }

    & ${BLabel} {
        font-weight: 600;
    }
`

const Combat = styled.div`
    

    & h1 {
        margin: 0;
        padding: 0;
    }

    & * {
        font-size: 1.5rem;
    }

    & ${Row} {
        margin-top: -3px;
    }

    & ${Row}:first-of-type {
        margin-top: 0;
    } 
`

const Proficiencies = styled.div`
    & ${Row} {
        margin-bottom: -3px;
    }
`

const WeaponCombat = styled.div`

    & h1 {
        text-align: center;
    }

    & ${Split} {
        margin-bottom: -3px;

        & > *:nth-of-type(2), & > *:nth-of-type(3), & > *:nth-of-type(4) {
            max-width: 4rem;
        }
        & > *:nth-of-type(5) {
            max-width: 5rem;
        }
        & > *:nth-of-type(6), & > *:nth-of-type(7) {
            max-width: 8rem;
        }
    }

    & ${BInput} {
        margin-right: -3px;
    }

    & ${BLabel} {
        flex: 1;
        margin-right: -3px;

    }

    & ${BLabel}, & ${BInput} {
        padding: .25rem;
        min-height: 1.8rem;
    }

    & ${BLabel} {
        font-weight: 600;
    }
`

const Col = styled.div`
    display: flex;
    flex-direction:column;
`

const Equipment = styled.div`

    & h1 {
        text-align: center;
    }

    & ${Col} {
        flex: 1;  
    }

    & ${Col} ${Split} {
        margin-bottom: -3px;

        & > *:nth-of-type(2) {
            max-width: 6rem;
        }

        & > *:nth-of-type(3) {
            max-width: 3rem;
        }
    }

& ${BInput} {
    margin-right: -3px;
}

& ${BLabel} {
    flex: 1;
    margin-right: -3px;

}

& ${BLabel}, & ${BInput} {
    padding: .25rem;
    min-height: 1.8rem;
}

& ${BLabel} {
    font-weight: 600;
}
`

const Movement = styled.div`
    & h1 {
        text-align: center;
    }

    & > ${Split} {
        margin-bottom: -3px;

        &:first-of-type ${BLabel} {
            flex: 1;
        }

        & ${BLabel}, & ${BInput} {
            margin-right: -3px;
            padding: .25rem;
        }
        
        & ${BLabel} {
            font-weight: 600;
        }
    }
`

const Experience = styled.div`
    
    & h1 {
        text-align: center;
    }

    & > ${Split} {
        margin-bottom: -3px;
    }

    & > ${Split} > * {
        margin-right: -3px;
    }

    & ${BLabel} {
        font-weight: 600;
    }

    & ${BLabel}, & ${BInput} {
        padding: .25rem;
        min-height: 2.19rem;
    }
`

const KitInfo = styled.div`
    flex: 1;
    border: 3px solid black;


`

const Honor = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 3px solid black;
    margin-left: -3px;

    & > ${Split} > ${Col}:first-of-type > ${Split}:first-of-type > ${BInput} {
        margin: 0 1rem 1rem;
        height: 4.5rem;
        max-width: 4.5rem;
    }

    & > ${Split} > ${Col}:first-of-type > ${Split}:nth-of-type(2) > ${BInput} {
        max-width: 4.5rem;
        margin: 0 1rem;
        min-height: 2rem;
    }
`

const Lines = styled.div`
    background-image: linear-gradient(black 2px, transparent 1px);
    background-size: 20px 30px;
    height: 10rem;
`

const ThiefSkills = styled.div`
    border-top: 3px solid black;

    & ${BLabel} {
        font-weight: 600;
    }

    & ${BLabel}, & ${BInput} {
        padding: .25rem;
        min-height: 2.19rem;
    }

    & > ${Split}:first-of-type > * {
        flex: 1;
    }

    & > ${Split} {
        margin-bottom: -3px;
        margin-left: -3px;
        margin-right: -3px;
    }

    & > ${Split} > * {
        margin-right: -3px;

        &:last-of-type {
            border-right: 0;
        }
    }
`

const PriestVS = styled.div`
    display: flex;
    border: 3px solid black;
    margin-top: -3px;
    margin-bottom: -3px;

    & h2 {
        text-align: center;
        margin: 0;

        &:first-of-type {
            margin-top: 2rem;
        }
        &:last-of-type {
            margin-bottom: 2rem;
        }
    }

    & ${Col}:nth-of-type(2) {
        border-left: 3px solid black;
        border-right: 3px solid black;
    }

    & ${Col}:nth-of-type(3) {
        border-right: 3px solid black;
    }

    & > ${Col} {
        & > ${Split} {
            flex: 1;

            & > span {
                white-space: nowrap;
                flex: 1;
            }
    
            & > div {
                flex: 1;
                max-width: 6rem;
                margin-right: -3px;
                margin-top: -3px;
            }
        }

        
    }
`

const Reputation = styled.div`
    flex: 1;
    
    & .adnd-established-reps {
        position: relative;
        top: 15px;

        & > p {
            margin-bottom: 0.5rem;
        }

        & > ${Split} {
            margin-bottom: -3px;
            margin-left: -3px;
            width: calc(100% + 3px);
        }

        & > ${Split} > *:first-of-type {
            max-width: 6rem;
        }

        & > ${Split} > * {
            margin-right: -3px;
            flex: 1;
        }
    }
`   

const Patrons = styled.div`
    flex: 1;
    border-left: 3px solid black;
    border-right: 3px solid black;
`

const SpellsPerLevel = styled.div`
    flex: 1;
    padding: 1rem;
`

const Henchmen = styled.div`
    & h1 {
        text-align: center;
    }

    & > ${Split} > * {
        flex: 1;
        margin-right: -3px;
    }

    & > ${Split} {
        margin-bottom: -3px;
    }

    & ${BLabel}, & ${BInput} {
        padding: .25rem;
        min-height: 2.19rem;
    }
`

const MartialArts = styled.div`

    & h1 {
        text-align: center;
    }

    & > ${Split} > * {
        flex: 1;
        margin-right: -3px;
    }

    & > ${Split} {
        margin-bottom: -3px;
    }

    & ${BLabel}, & ${BInput} {
        padding: .25rem;
        min-height: 2.19rem;
    }

    & > ${Split} {
        & > *:nth-of-type(2) {
            max-width: 3rem;
        }

        & > *:nth-of-type(3) {
            max-width: 3rem;
        }

        & > *:nth-of-type(4) {
            max-width: 9rem;
        }

        & > *:nth-of-type(5) {
            max-width: 7rem;
        }

        & > *:nth-of-type(6) {
            max-width: 10rem;
        }

        
    }
`

const Description = styled.div`
    & > ${Split} > ${Split} {
        margin-right: -3px;

        & > ${Split}:first-of-type {
            margin-right: -3px;
        }
    }

    & ${BLabel}, & ${BInput} {
        padding: .25rem;
        min-height: 2.19rem;
    }

    & > ${Split} {
        margin-bottom: -3px;
    }

    & > ${Split} > ${Col} > ${Split} {
        margin-bottom: -3px;

        & > *:first-of-type {
            margin-right: -3px;
        }
    }
`

const Psionics = styled.div`

    border-top: 3px solid black;

    & h2 {
        margin-left: 1rem;
    }

    & .adnd-sciences {
        width: calc(100% + 3px);
        margin-top: -10px;

        & > ${Split} {

            min-height: 2.265rem;

            & > * {
                flex: 1;
                margin-left: -3px;

                &:nth-of-type(2), &:nth-of-type(4) {
                    max-width: 4rem;
                }
            }
        }

        & > ${Split} {
            margin-bottom: -3px;
        }
    
        & ${BLabel}, & ${BInput} {
            padding: .25rem;
            min-height: 2.19rem;
        }
    }
`

const Container = styled.div`
    
    & > .adnd-card {
        padding: 4rem;
        max-width: 1280px;

        ${props => props.width < 840 ? `
            padding: 2rem;
        ` : ''}
    }

    & ${MobileStatRow} {
        display: none;
        gap: 1rem;
        justify-content: space-between;
        margin-top: -1.5rem;
        margin-bottom: 1.5rem;

        & h1 {
            font-size: 1.5rem;
        }
    }

    & .adnd-scores-throws-split > div:first-of-type {
        margin-right: 2rem;
    }


    ${props => props.width < 1280 ? `
        & .adnd-character-logo-split {

            flex-direction: column;

            & > div:first-of-type {
                order: 2;
            }
            & > div:last-of-type {
                order: 1;
            }
        }

        & .adnd-scores-throws-split {
            flex-direction: column;
        }

        & .adnd-combat-split {

            flex-wrap: wrap;

            & > div {
                flex-basis: 50% !important;
                max-width: unset !important;

                &:nth-of-type(1), &:nth-of-type(2) {
                    margin-bottom: -3px;
                }

                &:last-of-type {
                    margin-right: -3px;
                }
            }
        }

        & .adnd-movement-exp-split {
            flex-direction: column;

            & > *:nth-child(2) {
                & > *:nth-child(1) {
                    & > div > *:nth-child(1) {
                        border-left: 3px solid black !important;
                    }
                }
            }
        }

        & .adnd-kit-honor-split {
            flex-direction: column;

            & > *:last-child {
                margin-top: 2rem;
                margin-left: unset !important;
            }
        }

        & .adnd-priest-vs-split {
            flex-wrap: wrap;

            & > div {
                flex-basis: 50% !important;
                max-width: unset !important;

                &:nth-of-type(1) {
                    margin-right: -6px;

                    & > div {
                        width: calc(100% - 6px);
                    }
                }

                &:nth-of-type(2) {
                    margin-right: -3px;
                }

                &:nth-of-type(3) {
                    height: 9rem;
                    margin-top: 7px;
                }

                &:nth-of-type(4) {
                    margin-top: 6px;
                    margin-right: 0px !important;
                }

                &:nth-of-type(1), &:nth-of-type(2) {
                    margin-bottom: -3px;
                }

                &:last-of-type {
                    margin-right: -3px;
                }
            }
        }

        & .adnd-reputation-patrons-spells-split {
            flex-direction: column;

            & > *:nth-of-type(2) {
                border-left: 0;
                border-right: 0;
                border-top: 3px solid black;
                border-bottom: 3px solid black;
                margin-top: calc(1rem - 1px);
            }
        }
    ` : ''}

    ${props => props.width < 1040 ? `
        & .adnd-prof-split, & .adnd-combat-mod-split {
            flex-direction: column;
            gap: 1rem;

            & > div {
                margin-right: 0 !important;
            }
        }

        & .adnd-scores-throws-split > div:first-of-type {
            margin-right: 0;
        }
    ` : ''}
    
    ${props => props.width < 840 ? `
        & ${StatRow} > h1:first-of-type, & ${StatRow} > ${BInput}:first-of-type {
            display: none;
        }

        & ${MobileStatRow} {
            display: flex;
        }
    ` : ''}
`

export default ({characterId}) => {

    const {loading, error, data: {character} = {data: {character: {}}}} = useQuery(GET_CHARACTER, {
        variables: {
            characterId
        }
    })

    const [sheetRef, {width, height}] = useElementSize()

    const tipId = `adnd-character-${character?.id}-tooltip`

    return <Container ref={sheetRef} width={width}>
        <Card> 
            <Split style={{gap: '2rem', flex: 1}} className="adnd-character-logo-split">
                <div style={{display: 'flex', flexDirection: 'column', gap: '.5rem', flex: 1    }}>
                    <Row style={{gap: '.5rem', position: 'relative'}}>
                        <Split>
                            <Split style={{width: '60%', gap: '.5rem'}}>
                                <Label>Character</Label>
                                <UInput placeholder={loading} placeholderWidth="7.5rem" value={loading ? "" : character.name}></UInput>
                            </Split>

                            <Split style={{width: '40%', gap: '.5rem'}}>
                                <Label>Player</Label>
                                <UInput placeholder={loading} placeholderWidth="2rem" value={character?.player?.name || ''}></UInput>
                            </Split>
                            
                        </Split>
                        
                    </Row>

                    <Row>
                        <Split>
                            <Split style={{width: '66%', gap: '.5rem'}}>
                                <Label>Class/Kit</Label>
                                <UInput placeholder={loading} placeholderWidth="7.5rem" value={loading ? "" : character.class}></UInput>
                            </Split>

                            <Split style={{width: '34%', gap: '.5rem'}}>
                                <Label>Level</Label>
                                <UInput placeholder={loading} placeholderWidth="2rem" value={1}></UInput>
                            </Split>
                        </Split>
                    </Row>

                    <Row>
                        <Split>
                            <Split style={{width: '63%', gap: '.5rem'}}>
                                <Label>Race</Label>
                                <UInput placeholder={loading} value={loading ? "" : character.race}></UInput>
                            </Split>

                            <Split style={{width: '37%', gap: '.5rem', position: 'relative'}}>
                                <Label>Alignment</Label>
                                <UInput placeholder={loading} placeholderWidth="6rem" value={loading ? "" : character.alignment.replaceAll('-', ' ')}></UInput>
                                {loading ? <Shimmer width="4rem" height="1.5rem" style={{position: 'absolute', left: '14.5rem', top: '-3px'}} /> : null}
                            </Split>
                        </Split>
                    </Row>

                    <Row>
                        <Split>
                            <Split style={{gap: '.5rem'}}>
                                <Label style={{whiteSpace: 'nowrap'}}>Patron Deity / Religion</Label>
                                <UInput placeholder={loading} placeholderWidth="5.5rem" value={loading ? "" : character.religion}></UInput>
                            </Split>
                        </Split>
                    </Row>

                    <Row>
                        <Split>
                            <Split style={{gap: '.5rem'}}>
                                <Label style={{whiteSpace: 'nowrap'}}>Place of Origin</Label>
                                <UInput placeholder={loading} placeholderWidth="4rem" value={loading ? "" : character.origin}></UInput>
                            </Split>
                        </Split>
                    </Row>
                </div>

                <div>
                    <Title>
                        <div>Advanced</div>
                        <div>Dungeons <span>&</span> Dragons</div>
                    </Title>
                    <Subtitle>2nd Edition</Subtitle>
                    <Divider />
                    <div style={{textAlign: 'center', letterSpacing: '.15rem'}}>PLAYER CHARACTER RECORD</div>
                </div>
            </Split>
            
            <Split className="adnd-scores-throws-split">
                <div style={{flex: 1}}>
                    <h1>ABILITY SCORES</h1>
                    
                    <MobileStatRow>
                        <Col>
                            <h1 data-for={tipId} data-tip="Strength (Str) measures a character's muscle, endurance, and stamina.">STR</h1>
                            <BInput placeholder={loading} >{loading ? "??" : character.str}</BInput>
                        </Col>

                        <Col>
                            <h1 data-for={tipId} data-tip="Dexterity (Dex) encompasses several physical attributes including hand-eye coordination, agility, reaction speed, reflexes, and balance.">DEX</h1>
                            <BInput placeholder={loading}>{loading ? "??" : character.dex}</BInput>
                        </Col>
                        
                        <Col>
                            <h1 data-for={tipId} data-tip={"A character's Constitution (Con) score encompasses his physique, fitness, health, and physical resistance to hardship, injury, and disease."}>CON</h1>
                            <BInput placeholder={loading}>{loading ? "??" : character.con}</BInput>
                        </Col>

                        <Col>
                            <h1 data-for={tipId} data-tip="Intelligence (Int) represents a character's memory, reasoning, and learning ability, including areas outside those measured by the written word.">INT</h1>
                            <BInput placeholder={loading}>{loading ? "??" : character.int}</BInput>
                        </Col>

                        <Col>
                            <h1 data-for={tipId} data-tip="Wisdom (Wis) describes a composite of the character's enlightenment, judgment, guile, willpower, common sense, and intuition.">WIS</h1>
                            <BInput placeholder={loading}>{loading ? "??" : character.wis}</BInput>
                        </Col>

                        <Col>
                            <h1 data-for={tipId} data-tip="The Charisma (Cha) score measures a character's persuasiveness, personal magnetism, and ability to lead.">CHA</h1>
                            <BInput placeholder={loading}>{loading ? "??" : character.cha}</BInput>
                        </Col>
                    </MobileStatRow>
                    
                    <StatRow>
                        <h1 data-for={tipId} data-tip="Strength (Str) measures a character's muscle, endurance, and stamina.">STR</h1>
                        <BInput placeholder={loading} >{loading ? "??" : character.str}</BInput>

                        <BLabel style={{fontWeight: 600}} data-for={tipId} data-tip="Serves as an accuracy/penetration bonus or penalty against certain types of armor.">Hit<br />Adj</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.hitAdjustment}</BInput>

                        <BLabel style={{fontWeight: 600}}>Dmg<br />Adj</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.dmgAdjustment}</BInput>

                        <BLabel style={{fontWeight: 600}} data-for={tipId} data-tip="Max weight a character can carry before becoming encumbered.">Weight<br />Allow</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.weightAllowance}</BInput>

                        <BLabel style={{fontWeight: 600}} data-for={tipId} data-tip="The heaviest weight a character can pick up and lift over his head.">Max<br />Press</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.maxPress}</BInput>

                        <BLabel style={{fontWeight: 600}} data-for={tipId} data-tip="Chance of forcing open a door.">Open<br />Doors</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.openDoors}</BInput>

                        <BLabel style={{fontWeight: 600}} data-for={tipId} data-tip="Chance of bending a set of soft iron bars.">Bend<br />Bars</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.bendBars * 100 + "%"}</BInput>
                    </StatRow>

                    <StatRow style={{marginTop: '-3px'}}>
                        <h1 data-for={tipId} data-tip="Dexterity (Dex) encompasses several physical attributes including hand-eye coordination, agility, reaction speed, reflexes, and balance.">DEX</h1>
                        <BInput placeholder={loading}>{loading ? "??" : character.dex}</BInput>

                        <BLabel style={{fontWeight: 600}} data-for={tipId} data-tip="Bonus/penalty for surprise rolls">Surprise<br />Adjustment</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.surpriseAdjustment}</BInput>

                        <BLabel style={{fontWeight: 600}} data-for={tipId} data-tip="Bonus/penalty applied when attacking with a missle weapon">Missile Att<br />Adjustment</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.missileAdjustment}</BInput>

                        <BLabel style={{fontWeight: 600}} data-for={tipId} data-tip="Bonus/penalty applied to saving throws against attacks that can be dodged">Defensive<br />Adjustment</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.defenseAdjustment}</BInput>
                    </StatRow>

                    <StatRow style={{marginTop: '-3px'}}>
                        <h1 data-for={tipId} data-tip={"A character's Constitution (Con) score encompasses his physique, fitness, health, and physical resistance to hardship, injury, and disease."}>CON</h1>
                        <BInput placeholder={loading}>{loading ? "??" : character.con}</BInput>

                        <BLabel style={{fontWeight: 600}} data-for={tipId} data-tip="Bonus/penalty applied to hit die rolls for the character">HP<br />Adj</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.hpAdjustment}</BInput>

                        <BLabel style={{fontWeight: 600}} data-for={tipId} data-tip="Bonus/penalty applied to saving throws against death by system shock">System<br />Shock</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.systemShock}</BInput>

                        <BLabel style={{fontWeight: 600}} data-for={tipId} data-tip="Bonus/penalty applied to resurrection survival checks">Resurrect<br />Survival</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.resurrectSurvival}</BInput>

                        <BLabel style={{fontWeight: 600}} data-for={tipId} data-tip="Bonus/penalty applied to saving throws against effects of poison">Poison<br />Save</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.poisonSave}</BInput>

                        <BLabel style={{fontWeight: 600}}>Regen</BLabel>
                        <BInput placeholder={loading}></BInput>
                    </StatRow>

                    <StatRow style={{marginTop: '-3px'}}>
                        <h1 data-for={tipId} data-tip="Intelligence (Int) represents a character's memory, reasoning, and learning ability, including areas outside those measured by the written word.">INT</h1>
                        <BInput placeholder={loading}>{loading ? "??" : character.int}</BInput>

                        <BLabel style={{fontWeight: 600}} data-for={tipId} data-tip="bonus/penalty applied to skill checks, saving throws, or attacks for skills that a character is proficient in">Add<br />Profs</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.addProficiencies}</BInput>

                        <BLabel style={{fontWeight: 600}}>Spell<br />Level</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.maxSpellLevel}</BInput>

                        <BLabel style={{fontWeight: 600}} data-for={tipId} data-tip="Chance to learn a new spell">Learn<br />Spell</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.learnSpellChance}</BInput>

                        <BLabel style={{fontWeight: 600}} data-for={tipId} data-tip="">Max&nbsp;#<br />Spells</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.maxSpellsPerLevel}</BInput>

                        <BLabel style={{fontWeight: 600}}>Spell<br />Immun</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.intSpellImmunity}</BInput>
                    </StatRow>

                    <StatRow style={{marginTop: '-3px'}}>
                        <h1 data-for={tipId} data-tip="Wisdom (Wis) describes a composite of the character's enlightenment, judgment, guile, willpower, common sense, and intuition.">WIS</h1>
                        <BInput placeholder={loading}>{loading ? "??" : character.wis}</BInput>

                        <BLabel style={{fontWeight: 600}}>Magical<br />Def&nbsp;Adj</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.defenseAdjustment}</BInput>

                        <BLabel style={{fontWeight: 600}}>Bonus<br />Spells</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.bonusSpells}</BInput>

                        <BLabel style={{fontWeight: 600}}>Spell<br />Failure</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.spellFailure}</BInput>

                        <BLabel style={{fontWeight: 600}}>Spell<br />Immun</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.wisSpellImmunity}</BInput>
                    </StatRow>

                    <StatRow style={{marginTop: '-3px'}}>
                        <h1 data-for={tipId} data-tip="The Charisma (Cha) score measures a character's persuasiveness, personal magnetism, and ability to lead.">CHA</h1>
                        <BInput placeholder={loading}>{loading ? "??" : character.cha}</BInput>

                        <BLabel style={{fontWeight: 600}}>Max&nbsp;#&nbsp;of<br />Henchmen</BLabel>
                        <BInput placeholder={loading}></BInput>

                        <BLabel style={{fontWeight: 600}}>Loyalty<br />Base</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.loyaltyBase}</BInput>

                        <BLabel style={{fontWeight: 600}}>Reaction<br />Adjustment</BLabel>
                        <BInput placeholder={loading}>{loading ? "??" : character.reactionAdjustment}</BInput>
                    </StatRow>

                    
                </div>

                <SavingThrows>
                    <h1>SAVING THROWS</h1>
                    <Row style={{marginTop: '-1.25rem', minHeight: '1rem'}}>
                        <BLabel></BLabel>
                        <BInput value="Start" />
                        <BInput value="Mod" />
                        <BInput value="Total" />
                        <BInput value="+/-" />
                        <BInput value="Modifier" />
                    </Row>
                    <Row>
                        <BLabel style={{fontWeight: 600}}>Paralyzation/<br />Poison/Death</BLabel>
                        <BInput placeholder={loading} style={{width: '4.5rem'}}></BInput>

                        <BInput placeholder={loading}></BInput>
                        <BInput placeholder={loading} style={{borderTop: '6px solid black', borderLeft: '6px solid black', borderRight: '6px solid black' }}></BInput>
                        <BInput placeholder={loading}></BInput>
                        <BInput placeholder={loading}></BInput>
                    </Row>
                    <Row>
                        <BLabel style={{fontWeight: 600}}>Rod,&nbsp;Staff,/<br />or&nbsp;Wand</BLabel>
                        <BInput placeholder={loading} style={{width: '4.5rem'}}></BInput>

                        <BInput placeholder={loading}></BInput>
                        <BInput placeholder={loading} style={{borderLeft: '6px solid black', borderRight: '6px solid black' }}></BInput>
                        <BInput placeholder={loading}></BInput>
                        <BInput placeholder={loading}></BInput>
                    </Row>
                    <Row>
                        <BLabel style={{fontWeight: 600}}>Petrification/<br />Polymorph</BLabel>
                        <BInput placeholder={loading} style={{width: '4.5rem'}}></BInput>

                        <BInput placeholder={loading}></BInput>
                        <BInput placeholder={loading} style={{borderLeft: '6px solid black', borderRight: '6px solid black' }}></BInput>
                        <BInput placeholder={loading}></BInput>
                        <BInput placeholder={loading}></BInput>
                    </Row>
                    <Row>
                        <BLabel style={{fontWeight: 600}}>Breath<br />Weapon</BLabel>
                        <BInput placeholder={loading} style={{width: '4.5rem'}}></BInput>

                        <BInput placeholder={loading}></BInput>
                        <BInput placeholder={loading} style={{borderLeft: '6px solid black', borderRight: '6px solid black' }}></BInput>
                        <BInput placeholder={loading}></BInput>
                        <BInput placeholder={loading}></BInput>
                    </Row>
                    <Row>
                        <BLabel style={{fontWeight: 600}}>Spell</BLabel>
                        <BInput placeholder={loading} style={{width: '4.5rem'}}></BInput>

                        <BInput placeholder={loading}></BInput>
                        <BInput placeholder={loading} style={{borderBottom: '6px solid black', borderLeft: '6px solid black', borderRight: '6px solid black' }}></BInput>
                        <BInput placeholder={loading}></BInput>
                        <BInput placeholder={loading}></BInput>
                    </Row>
                    <Row>
                        <BLabel style={{fontWeight: 600}}>Spell<br />Resistance</BLabel>

                        <BInput placeholder={loading} style={{width: '100%'}}></BInput>
                    </Row>
                </SavingThrows>
            </Split>

            <h1 style={{textAlign: 'center'}}>Combat</h1>

            <Combat>
                <Split className='adnd-combat-split'>
                    <Box style={{display: 'flex', flex: '1'}}>
                        <Split style={{flex: 1}}>
                            <div style={{position: 'relative', margin: '.5rem 0 .5rem .5rem', textAlign: 'center'}}>
                                <h1>ARMOR</h1>
                                <BLabel shield><span style={{position: 'relative', top: '1rem', left: '1rem'}}>XX</span></BLabel>
                                <h1 style={{marginTop: '2.75rem'}}>CLASS</h1>
                            </div>
                            <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                                <Row style={{width: '100%', flex: 1, justifyContent: 'right', alignItems: 'center', border: '3px solid transparent', borderTop: '0'}}>Surprised AC&nbsp;</Row>
                                <Row style={{width: '100%', flex: 1, justifyContent: 'right', alignItems: 'center', border: '3px solid transparent'}}>Shieldless AC&nbsp;</Row>
                                <Row style={{width: '100%', flex: 1, justifyContent: 'right', alignItems: 'center', border: '3px solid transparent'}}>Rear AC&nbsp;</Row>
                                <Row style={{width: '100%', flex: 1, justifyContent: 'right', alignItems: 'center', border: '3px solid transparent', borderBottom: '0'}}>Type Worn&nbsp;</Row>
                            </div>
                        </Split>
                    </Box>
                    <div style={{marginLeft: '-3px', display: 'flex', flexDirection: 'column'}}>
                        <Row style={{flex: 1}}>
                            <BInput placeholder={loading} style={{minWidth: '4rem', maxWidth: '4rem'}}></BInput>
                            <div style={{flex: 1, borderTop: '3px solid black', display: 'flex', alignItems: 'center', justifyContent: 'right', paddingLeft: '.5rem'}}>DEX&nbsp;Checks&nbsp;</div>
                            <BInput placeholder={loading} style={{minWidth: '4rem', maxWidth: '4rem'}}></BInput>
                        </Row>
                        <Row style={{flex: 1}}>
                            <BInput placeholder={loading} style={{minWidth: '4rem', maxWidth: '4rem'}}></BInput>
                            <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'right', paddingLeft: '.5rem'}}>Vision&nbsp;Checks&nbsp;</div>
                            <BInput placeholder={loading} style={{minWidth: '4rem', maxWidth: '4rem'}}></BInput>
                        </Row>
                        <Row style={{flex: 1}}>
                            <BInput placeholder={loading} style={{minWidth: '4rem', maxWidth: '4rem'}}></BInput>
                            <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'right', paddingLeft: '.5rem'}}>Hearing&nbsp;Checks&nbsp;</div>
                            <BInput placeholder={loading} style={{minWidth: '4rem', maxWidth: '4rem'}}></BInput>
                        </Row>
                        <Row style={{flex: 1}}>
                            <BInput placeholder={loading} style={{flex: 1}}></BInput>
                        </Row>
                    </div>
                    <Box style={{flex: '1', display: 'flex'}}>
                        <Split style={{flex: 1}}>
                            <div style={{marginLeft: '1rem'}}>
                                <h1 style={{whiteSpace: 'nowrap'}}>HIT POINTS</h1>
                                <BLabel hp>XX</BLabel>
                                <h1 style={{position: 'relative', top: '.4rem'}}>Hit die: </h1>
                            </div>
                            <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                                <Row style={{flex: 1, justifyContent: 'right', alignItems: 'center', border: '3px solid transparent'}}>Numbed&nbsp;#&nbsp;</Row>
                                <Row style={{flex: 1, justifyContent: 'right', alignItems: 'center', border: '3px solid transparent'}}>Useless&nbsp;#&nbsp;</Row>
                                <Row style={{flex: 1, justifyContent: 'right', alignItems: 'center', border: '3px solid transparent'}}>Max&nbsp;Deaths&nbsp;</Row>
                                <Row style={{flex: 1, justifyContent: 'right', alignItems: 'center', border: '3px solid transparent'}}>Deaths&nbsp;</Row>
                            </div>
                        </Split>
                    </Box>
                    <Box style={{display: 'flex', flex: '1', maxWidth: '18rem', marginLeft: '-3px'}}>
                        <Split style={{flex: 1}}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <Row style={{flex: 1}} ><BInput placeholder={loading} style={{marginTop: '-3px', marginLeft: '-3px', width: '4rem'}}></BInput></Row>
                                <Row style={{flex: 1}} ><BInput placeholder={loading} style={{marginLeft: '-3px', width: '4rem'}}></BInput></Row>
                                <Row style={{flex: 1}} ><BInput placeholder={loading} style={{marginLeft: '-3px', width: '4rem'}}></BInput></Row>
                                <Row style={{flex: 1}} ><BInput placeholder={loading} style={{marginBottom: '-3px', marginLeft: '-3px', width: '4rem'}}></BInput></Row>
                            </div>
                            <div style={{flex: 1, margin: '.25rem .5rem', textAlign: 'center'}}>
                                <h1>Wounds</h1>
                            </div>
                        </Split>
                    </Box>
                </Split>
            </Combat>

            <TargetAC>
                <Split>
                    <BLabel style={{minWidth: '6rem', whiteSpace: 'nowrap'}}>Target's AC</BLabel>
                    <BLabel style={{flex: 1, textAlign: 'center', justifyContent: 'center'}}>10</BLabel>
                    <BLabel style={{flex: 1, textAlign: 'center', justifyContent: 'center'}}>9</BLabel>
                    <BLabel style={{flex: 1, textAlign: 'center', justifyContent: 'center'}}>8</BLabel>
                    <BLabel style={{flex: 1, textAlign: 'center', justifyContent: 'center'}}>7</BLabel>
                    <BLabel style={{flex: 1, textAlign: 'center', justifyContent: 'center'}}>6</BLabel>
                    <BLabel style={{flex: 1, textAlign: 'center', justifyContent: 'center'}}>5</BLabel>
                    <BLabel style={{flex: 1, textAlign: 'center', justifyContent: 'center'}}>4</BLabel>
                    <BLabel style={{flex: 1, textAlign: 'center', justifyContent: 'center'}}>3</BLabel>
                    <BLabel style={{flex: 1, textAlign: 'center', justifyContent: 'center'}}>2</BLabel>
                    <BLabel style={{flex: 1, textAlign: 'center', justifyContent: 'center'}}>1</BLabel>
                    <BLabel style={{flex: 1, textAlign: 'center', justifyContent: 'center', borderTop: '6px solid black', borderLeft: '6px solid black', borderRight: '6px solid black'}}>0</BLabel>
                    <BLabel style={{flex: 1, textAlign: 'center', justifyContent: 'center'}}>-1</BLabel>
                    <BLabel style={{flex: 1, textAlign: 'center', justifyContent: 'center'}}>-2</BLabel>
                    <BLabel style={{flex: 1, textAlign: 'center', justifyContent: 'center'}}>-3</BLabel>
                    <BLabel style={{flex: 1, textAlign: 'center', justifyContent: 'center'}}>-4</BLabel>
                    <BLabel style={{flex: 1, textAlign: 'center', justifyContent: 'center'}}>-5</BLabel>
                    <BLabel style={{flex: 1, textAlign: 'center', justifyContent: 'center'}}>-6</BLabel>
                    <BLabel style={{flex: 1, textAlign: 'center', justifyContent: 'center'}}>-7</BLabel>
                    <BLabel style={{flex: 1, textAlign: 'center', justifyContent: 'center'}}>-8</BLabel>
                    <BLabel style={{flex: 1, textAlign: 'center', justifyContent: 'center'}}>-9</BLabel>
                    <BLabel style={{flex: 1, textAlign: 'center', justifyContent: 'center'}}>-10</BLabel>
                </Split>

                <Split>
                    <BLabel style={{minWidth: '6rem', whiteSpace: 'nowrap'}}>To Hit #</BLabel>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading} style={{borderBottom: '6px solid black', borderLeft: '6px solid black', borderRight: '6px solid black'}}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                </Split>
            </TargetAC>

            <CombatMods>
            <h1 style={{textAlign: 'center'}}>Combat Modifiers</h1>
            <Split className='adnd-combat-mod-split'>
                <div style={{marginRight: '2rem', flex: 1}}>
                    <Row>
                        <BLabel style={{flex: 1}}>To Hit Modifiers</BLabel>
                        <BLabel style={{width: '4rem', justifyContent: 'center'}}>+ / -</BLabel>
                    </Row>
                    <Row style={{marginTop: '-3px'}}>
                        <BLabel style={{flex: 1}}>Non-proficiency penalty</BLabel>
                        <BInput placeholder={loading} style={{width: '4rem', justifyContent: 'center'}}></BInput>
                    </Row>
                    <Row style={{marginTop: '-3px'}}>
                        <BInput placeholder={loading} style={{flex: 1}}></BInput>
                        <BInput placeholder={loading} style={{width: '4rem', justifyContent: 'center'}}></BInput>
                    </Row>
                    <Row style={{marginTop: '-3px'}}>
                        <BInput placeholder={loading} style={{flex: 1}}></BInput>
                        <BInput placeholder={loading} style={{width: '4rem', justifyContent: 'center'}}></BInput>
                    </Row>
                </div>

                <div style={{marginRight: '2rem', flex: 1}}>
                    <Row>
                        <BLabel style={{flex: 1}}>Damage Modifiers</BLabel>
                        <BLabel style={{width: '4rem', justifyContent: 'center'}}>+ / -</BLabel>
                    </Row>
                    <Row  style={{marginTop: '-3px'}}>
                        <BInput placeholder={loading} style={{flex: 1}}></BInput>
                        <BInput placeholder={loading} style={{width: '4rem', justifyContent: 'center'}}></BInput>
                    </Row>
                    <Row style={{marginTop: '-3px'}}>
                        <BInput placeholder={loading} style={{flex: 1}}></BInput>
                        <BInput placeholder={loading} style={{width: '4rem', justifyContent: 'center'}}></BInput>
                    </Row>
                    <Row style={{marginTop: '-3px'}}>
                        <BInput placeholder={loading} style={{flex: 1}}></BInput>
                        <BInput placeholder={loading} style={{width: '4rem', justifyContent: 'center'}}></BInput>
                    </Row>
                </div>

                <div style={{flex: 1}}>
                    <Row>
                        <BLabel style={{flex: 1}}>AC Modifiers</BLabel>
                        <BLabel style={{width: '4rem', justifyContent: 'center'}}>+ / -</BLabel>
                    </Row>
                    <Row style={{marginTop: '-3px'}}>
                        <BInput placeholder={loading} style={{flex: 1}}></BInput>
                        <BInput placeholder={loading} style={{width: '4rem', justifyContent: 'center'}}></BInput>
                    </Row>
                    <Row style={{marginTop: '-3px'}}>
                        <BInput placeholder={loading} style={{flex: 1}}></BInput>
                        <BInput placeholder={loading} style={{width: '4rem', justifyContent: 'center'}}></BInput>
                    </Row>
                    <Row style={{marginTop: '-3px'}}>
                        <BInput placeholder={loading} style={{flex: 1}}></BInput>
                        <BInput placeholder={loading} style={{width: '4rem', justifyContent: 'center'}}></BInput>
                    </Row>
                </div>
            </Split>
            </CombatMods>

            <Profs>
                <h1 style={{textAlign: 'center'}}>Proficiencies</h1>
                <Proficiencies>
                    <Split className='adnd-prof-split'>
                        <div style={{flex: 1, marginRight: '2rem'}}>
                            <Row>
                                <BLabel style={{flex: 1}}>Proficiency</BLabel>
                                <BLabel style={{width: '4rem'}}>Slots</BLabel>
                                <BLabel style={{width: '4rem'}}>Chk</BLabel>
                            </Row>

                            <Row>
                                <BInput placeholder={loading} style={{flex: 1}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                            </Row>

                            <Row>
                                <BInput placeholder={loading} style={{flex: 1}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                            </Row>

                            <Row>
                                <BInput placeholder={loading} style={{flex: 1}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                            </Row>
                            
                            <Row>
                                <BInput placeholder={loading} style={{flex: 1}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                            </Row>

                            <Row>
                                <BInput placeholder={loading} style={{flex: 1}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                            </Row>

                            <Row>
                                <BInput placeholder={loading} style={{flex: 1}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                            </Row>
                            
                            <Row>
                                <BInput placeholder={loading} style={{flex: 1}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                            </Row>
                        </div>

                        <div style={{flex: 1, marginRight: '2rem'}}>
                            <Row>
                                <BLabel style={{flex: 1}}>Proficiency</BLabel>
                                <BLabel style={{width: '4rem'}}>Slots</BLabel>
                                <BLabel style={{width: '4rem'}}>Chk</BLabel>
                            </Row>

                            <Row>
                                <BInput placeholder={loading} style={{flex: 1}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                            </Row>

                            <Row>
                                <BInput placeholder={loading} style={{flex: 1}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                            </Row>

                            <Row>
                                <BInput placeholder={loading} style={{flex: 1}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                            </Row>
                            
                            <Row>
                                <BInput placeholder={loading} style={{flex: 1}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                            </Row>

                            <Row>
                                <BInput placeholder={loading} style={{flex: 1}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                            </Row>

                            <Row>
                                <BInput placeholder={loading} style={{flex: 1}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                            </Row>
                            
                            <Row>
                                <BInput placeholder={loading} style={{flex: 1}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                            </Row>
                        </div>

                        <div style={{flex: 1}}>
                            <Row>
                                <BLabel style={{flex: 1}}>Proficiency</BLabel>
                                <BLabel style={{width: '4rem'}}>Slots</BLabel>
                                <BLabel style={{width: '4rem'}}>Chk</BLabel>
                            </Row>

                            <Row>
                                <BInput placeholder={loading} style={{flex: 1}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                            </Row>

                            <Row>
                                <BInput placeholder={loading} style={{flex: 1}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                            </Row>

                            <Row>
                                <BInput placeholder={loading} style={{flex: 1}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                            </Row>
                            
                            <Row>
                                <BInput placeholder={loading} style={{flex: 1}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                            </Row>

                            <Row>
                                <BInput placeholder={loading} style={{flex: 1}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                            </Row>

                            <Row>
                                <BInput placeholder={loading} style={{flex: 1}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                            </Row>
                            
                            <Row>
                                <BInput placeholder={loading} style={{flex: 1}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                                <BInput placeholder={loading} style={{width: '4rem'}}></BInput>
                            </Row>
                        </div>
                    </Split>
                </Proficiencies>
            </Profs>

            <WeaponCombat>
                <h1>Weapon Combat</h1>
                <Split>
                    <BLabel>Weapon</BLabel>
                    <BLabel>#AT</BLabel>
                    <BLabel>Size</BLabel>
                    <BLabel>Type</BLabel>
                    <BLabel>Speed</BLabel>
                    <BLabel>Hit/Dmg Adj</BLabel>
                    <BLabel>Damage</BLabel>
                    <BLabel>Range/Special</BLabel>
                </Split>
                {[1,2,3,4,5,6,7,8].map(() => <Split>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                    <BInput placeholder={loading}></BInput>
                </Split>)}
            </WeaponCombat>

            <Equipment>
                <h1>Equipment</h1>
                <Split>
                    <Col>
                        <Split>
                            <BLabel>Item</BLabel>
                            <BLabel>Location</BLabel>
                            <BLabel>Wt</BLabel>
                        </Split>
                        {[1,2,3,4,5,6,7,8,9,10,11,12].map(() => <Split>
                            <BInput placeholder={loading}></BInput>
                            <BInput placeholder={loading}></BInput>
                            <BInput placeholder={loading}></BInput>
                        </Split>)}
                    </Col>
                    <Col>
                        <Split>
                            <BLabel>Item</BLabel>
                            <BLabel>Location</BLabel>
                            <BLabel>Wt</BLabel>
                        </Split>
                        {[1,2,3,4,5,6,7,8,9,10,11,12].map(() => <Split>
                            <BInput placeholder={loading}></BInput>
                            <BInput placeholder={loading}></BInput>
                            <BInput placeholder={loading}></BInput>
                        </Split>)}
                    </Col>
                    <Col>
                        <Split>
                            <BLabel>Item</BLabel>
                            <BLabel>Location</BLabel>
                            <BLabel>Wt</BLabel>
                        </Split>
                        {[1,2,3,4,5,6,7,8,9,10,11,12].map(() => <Split>
                            <BInput placeholder={loading}></BInput>
                            <BInput placeholder={loading}></BInput>
                            <BInput placeholder={loading}></BInput>
                        </Split>)}
                    </Col>
                </Split>
                
                <Split style={{borderTop: '3px solid black'}}>
                    <BLabel style={{whiteSpace: 'nowrap'}}>Total Weight</BLabel>
                    <BInput></BInput>
                    <BLabel  style={{whiteSpace: 'nowrap'}}>Encumberance</BLabel>
                    <BInput></BInput>
                    <BLabel  style={{whiteSpace: 'nowrap'}}>Movement Rate</BLabel>
                    <BInput></BInput>
                </Split>
            </Equipment>

            <Split style={{flex: 1}} className="adnd-movement-exp-split">
                <Col style={{flex: 1}}>
                    <Movement>
                        <h1>Movement</h1>
                        <Split>
                            <BLabel>Movement</BLabel>
                            <BLabel style={{maxWidth: '5rem'}}>Rate</BLabel>
                            <BLabel>Movement</BLabel>
                            <BLabel style={{maxWidth: '5rem'}}>Rate</BLabel>
                        </Split>
                        <Split>
                            <BLabel style={{flex: 1}}>Base</BLabel>
                            <BInput style={{maxWidth: '5rem'}}  placeholder={loading}>{loading ? "??" : character.baseMovement * 10 + 'yd'}</BInput>
                            <BLabel style={{flex: 1}}>Run (x5)</BLabel>
                            <BInput style={{maxWidth: '5rem'}} placeholder={loading}>{loading ? "??" : (character.baseMovement * 10) * 5 + 'yd'}</BInput>
                        </Split>
                        <Split>
                            <BLabel style={{flex: 1}}>Jog (x 2)</BLabel>
                            <BInput style={{maxWidth: '5rem'}}  placeholder={loading}>{loading ? "??" : (character.baseMovement * 10) * 2 + 'yd'}</BInput>
                            <BLabel style={{flex: 1}}>Day</BLabel>
                            <BInput style={{maxWidth: '5rem'}} placeholder={loading}>{loading ? '??' : (character.baseMovement * 2) + 'mi'}</BInput>
                        </Split>
                        <Split>
                            <BLabel style={{flex: 1}}>Run (x 3)</BLabel>
                            <BInput style={{maxWidth: '5rem'}}  placeholder={loading}>{loading ? "??" : (character.baseMovement * 10) * 3 + 'yd'}</BInput>
                            <BLabel style={{flex: 1}}></BLabel>
                            <BInput style={{maxWidth: '5rem'}} placeholder={loading}></BInput>
                        </Split>
                        <Split>
                            <BLabel style={{flex: 1}}>Run (x 4)</BLabel>
                            <BInput style={{maxWidth: '5rem'}}  placeholder={loading}>{loading ? "??" : (character.baseMovement * 10) * 4 + 'yd'}</BInput>
                            <BLabel style={{flex: 1}}></BLabel>
                            <BInput style={{maxWidth: '5rem'}} placeholder={loading}></BInput>
                        </Split>

                        <Split>
                            <BLabel style={{flex: 1, maxWidth: '13.4rem'}}>Encumberance<br />Category</BLabel>
                            <BLabel style={{flex: '.6'}}>Weight<br />Carried</BLabel>
                            <BLabel style={{flex: 1, maxWidth: '5rem'}}>Move Rate</BLabel>
                            <BLabel style={{flex: 1, maxWidth: '5rem'}}>Attack Penalty</BLabel>
                            <BLabel style={{flex: 1, maxWidth: '5rem'}}>AC Penalty</BLabel>
                        </Split>
                        <Split>
                            <BLabel style={{flex: 1, maxWidth: '13.4rem'}}>Light</BLabel>
                            <BLabel style={{flex: '.6'}}></BLabel>
                            <BLabel style={{flex: 1, maxWidth: '5rem'}}></BLabel>
                            <BLabel style={{flex: 1, maxWidth: '5rem'}}>-</BLabel>
                            <BLabel style={{flex: 1, maxWidth: '5rem'}}>-</BLabel>
                        </Split>
                        <Split>
                            <BLabel style={{flex: 1, maxWidth: '13.4rem'}}>Moderate</BLabel>
                            <BLabel style={{flex: '.6'}}></BLabel>
                            <BLabel style={{flex: 1, maxWidth: '5rem'}}></BLabel>
                            <BLabel style={{flex: 1, maxWidth: '5rem'}}>-1</BLabel>
                            <BLabel style={{flex: 1, maxWidth: '5rem'}}>-</BLabel>
                        </Split>
                        <Split>
                            <BLabel style={{flex: 1, maxWidth: '13.4rem'}}>Heavy</BLabel>
                            <BLabel style={{flex: '.6'}}></BLabel>
                            <BLabel style={{flex: 1, maxWidth: '5rem'}}></BLabel>
                            <BLabel style={{flex: 1, maxWidth: '5rem'}}>-2</BLabel>
                            <BLabel style={{flex: 1, maxWidth: '5rem'}}>+1</BLabel>
                        </Split>
                        <Split>
                            <BLabel style={{flex: 1, maxWidth: '13.4rem'}}>Severe</BLabel>
                            <BLabel style={{flex: '.6'}}></BLabel>
                            <BLabel style={{flex: 1, maxWidth: '5rem'}}>1</BLabel>
                            <BLabel style={{flex: 1, maxWidth: '5rem'}}>-4</BLabel>
                            <BLabel style={{flex: 1, maxWidth: '5rem'}}>+3</BLabel>
                        </Split>
                    </Movement>
                </Col>

                <Col style={{flex: 1}}>
                    <Experience>
                        <h1>Experience</h1>

                        <Split>
                            <BLabel style={{flex: 1, borderBottom: '0', borderLeft: '6px solid black', justifyContent:'center'}}>Total XP</BLabel>
                            <BLabel style={{flex: 1, borderBottom: '0', justifyContent:'center'}}>XP for Next Level</BLabel>
                        </Split>

                        <Split>
                            <BInput placeholder={loading} style={{minHeight: '4.1rem', borderLeft: '6px solid black', borderTop: '0'}} />
                            <BInput placeholder={loading} style={{minHeight: '4.1rem', borderTop: '0'}} />
                        </Split>

                        <Split>
                            <BLabel style={{flex: 1, borderLeft: '6px solid black'}}>Kit Modifier</BLabel>
                            <BInput style={{maxWidth: '7rem'}} placeholder={loading} />
                            <BLabel style={{flex: 1}}>Ability Bonus</BLabel>
                            <BInput style={{maxWidth: '7rem'}} placeholder={loading} />
                        </Split>

                        <Split>
                            <BLabel style={{flex: 1, borderLeft: '6px solid black'}}>Subrace Modifier</BLabel>
                            <BInput style={{maxWidth: '7rem'}} placeholder={loading} />
                            <BLabel style={{flex: 1}}>Level Limit</BLabel>
                            <BInput style={{maxWidth: '7rem'}} placeholder={loading} />
                        </Split>

                        <Split>
                            <BLabel style={{flex: 1, borderLeft: '6px solid black'}}>Level Changes</BLabel>
                            <BLabel style={{flex: 1, maxWidth: '4rem'}}>By</BLabel>
                            <BLabel style={{flex: '.6'}}>At Levels</BLabel>
                        </Split>

                        <Split>
                            <BLabel style={{flex: 1, borderLeft: '6px solid black'}}>THAC0</BLabel>
                            <BInput style={{flex: 1, maxWidth: '4rem'}}></BInput>
                            <BInput style={{flex: '.6'}}></BInput>
                        </Split>

                        <Split>
                            <BLabel style={{flex: 1, borderLeft: '6px solid black'}}>Saving Throws</BLabel>
                            <BInput style={{flex: 1, maxWidth: '4rem'}}></BInput>
                            <BInput style={{flex: '.6'}}></BInput>
                        </Split>

                        <Split>
                            <BLabel style={{flex: 1, borderLeft: '6px solid black'}}>Weapon Proficiences</BLabel>
                            <BInput style={{flex: 1, maxWidth: '4rem'}}></BInput>
                            <BInput style={{flex: '.6'}}></BInput>
                        </Split>

                        <Split>
                            <BLabel style={{flex: 1, borderLeft: '6px solid black'}}>Non-weapon Proficiences</BLabel>
                            <BInput style={{flex: 1, maxWidth: '4rem'}}></BInput>
                            <BInput style={{flex: '.6'}}></BInput>
                        </Split>
                    </Experience>
                </Col>
            </Split>

            <Split style={{marginTop: '2rem'}} className="adnd-kit-honor-split">
                <KitInfo>
                    <div style={{padding: '0 1rem 1rem 1rem'}}>
                        <h2>Character Class / Kit Information</h2>
                        
                        <Lines>
                            <BLabel style={{border: '0', background: 'white'}}>Special Powers / Benefits:</BLabel>
                        </Lines>

                        <Lines>
                            <BLabel style={{border: '0', background: 'white'}}>Special Hinderances:</BLabel>
                        </Lines>

                        <Lines>
                            <BLabel style={{border: '0', background: 'white'}}>Class / Kit Notes:</BLabel>
                        </Lines>
                    </div>

                    <ThiefSkills>
                        <h2>Thieving Abilities</h2>

                        <Split>
                            <BLabel style={{minWidth: '9rem', border: '0'}}></BLabel>
                            <BLabel>Base</BLabel>
                            <BLabel>Skill</BLabel>
                            <BLabel>Race</BLabel>
                            <BLabel>Dex</BLabel>
                            <BLabel>Kit</BLabel>
                            <BLabel>Armor</BLabel>
                            <BLabel>Total</BLabel>
                        </Split>

                        <Split>
                            <BLabel style={{minWidth: '9rem'}}>Pick Pockets</BLabel>
                            <BInput></BInput>
                            <BInput>{!loading && !error ? character.thiefSkills.pickpocket : '??'}</BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>

                        <Split>
                            <BLabel style={{minWidth: '9rem'}}>Open Locks</BLabel>
                            <BInput></BInput>
                            <BInput>{!loading && !error ? character.thiefSkills.openlocks : '??'}</BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>

                        <Split>
                            <BLabel style={{minWidth: '9rem'}}>Find Traps</BLabel>
                            <BInput></BInput>
                            <BInput>{!loading && !error ? character.thiefSkills.findtraps : '??'}</BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>

                        <Split>
                            <BLabel style={{minWidth: '9rem'}}>Move Silently</BLabel>
                            <BInput></BInput>
                            <BInput>{!loading && !error ? character.thiefSkills.movesilently : '??'}</BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>

                        <Split>
                            <BLabel style={{minWidth: '9rem'}}>Hide in Shadows</BLabel>
                            <BInput></BInput>
                            <BInput>{!loading && !error ? character.thiefSkills.hideinshadows : '??'}</BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>

                        <Split>
                            <BLabel style={{minWidth: '9rem'}}>Detect Noise</BLabel>
                            <BInput></BInput>
                            <BInput>{!loading && !error ? character.thiefSkills.detectnoise : '??'}</BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>

                        <Split>
                            <BLabel style={{minWidth: '9rem'}}>Climb Walls</BLabel>
                            <BInput></BInput>
                            <BInput>{!loading && !error ? character.thiefSkills.climbwall : '??'}</BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>

                        <Split>
                            <BLabel style={{minWidth: '9rem'}}>Read Languages</BLabel>
                            <BInput></BInput>
                            <BInput>{!loading && !error ? character.thiefSkills.readlanguages : '??'}</BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>
                        
                        <Split>
                            <BLabel style={{flex: 1}}>Backstab Damage Modifier:</BLabel>
                            <BInput style={{maxWidth: '4rem'}}></BInput>
                        </Split>
                    </ThiefSkills>
                </KitInfo>

                <Honor>
                    <div style={{padding: '0 1rem 1rem 1rem'}}>
                        <h2>Honor / Station</h2>
                        <Split style={{flex: 1}}>
                            <Col>
                                <Split>
                                    <BInput />
                                    Honor / Station
                                </Split>
                                <Split>
                                    <BInput />
                                    Base
                                </Split>
                            </Col>
                            <Col style={{flex: 1}}>
                                <Lines style={{height: '6rem', backgroundPositionY: '1.5rem', alignItems: 'center'}}>
                                    <BLabel style={{border: 0}}>Birth:</BLabel>
                                </Lines>

                                <Lines style={{marginTop: '1rem', height: '2rem', backgroundPositionY: '1.5rem', alignItems: 'center', marginBottom: '.75rem'}}>
                                    <BLabel style={{border: 0}}>Reaction Adjustment:</BLabel>
                                </Lines>
                            </Col>
                        </Split>
                    </div>

                    <Psionics>
                    <h2>Psionics</h2>
                    <Split style={{margin: '0 1rem 1rem 1rem', gap: '1rem'}}>
                        <Split style={{flex: 1}}>
                            <h2 style={{flex: '.3', marginLeft: '1.75rem'}}>PSPs</h2>
                            <BInput style={{flex: '1', margin: '0px 2rem 0 3rem'}} />
                        </Split>

                        <Col style={{flex: 1}}>
                            <p style={{marginTop: 0, fontWeight: 600}}>Recovery Rates:</p>
                            <Split>
                                <BLabel style={{border: 0, fontWeight: 600, minWidth: '5rem'}}>Walking</BLabel>
                                <BLabel style={{border: 0}}>3/hour</BLabel>
                            </Split>
                            <Split>
                                <BLabel style={{border: 0, fontWeight: 600, minWidth: '5rem'}}>Resting</BLabel>
                                <BLabel style={{border: 0}}>6/hour (1/turn)</BLabel>
                            </Split>
                            <Split>
                                <BLabel style={{border: 0, fontWeight: 600, minWidth: '5rem'}}>Sleeping</BLabel>
                                <BLabel style={{border: 0}}>12/hour (2/turn)</BLabel>
                            </Split>
                        </Col>
                    </Split>

                    <Lines style={{height: '6rem', backgroundPositionY: '26px'}}>
                        <BLabel style={{border: 0}}>Disciplines:</BLabel>
                    </Lines>

                    <div className="adnd-sciences">
                        <Split>
                            <BLabel>Science/Devotion</BLabel>
                            <BLabel>PS</BLabel>
                            <BLabel>Science/Devotion</BLabel>
                            <BLabel>PS</BLabel>
                        </Split>
                        <Split>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>
                        <Split>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>
                        <Split>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>
                        <Split>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>
                        <Split>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>
                        <Split>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>
                        <Split>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>
                        <Split>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>
                        <Split>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>
                        <Split>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>
                        <Split>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>
                        <Split>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>
                        <Split>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>
                    </div>
                </Psionics>
                </Honor>

                
            </Split>

            <PriestVS className='adnd-priest-vs-split'>
                <Col style={{flex: 1}}>
                    <h2>Priest Versus</h2>
                    <h2>Undead</h2>
                    <Split>
                        <span>Skeleton or 1 HD</span>
                        <BInput style={{marginBottom: '-3px'}}>{!loading && !error && character.class.includes('cleric') ? 10 : '--'}</BInput>
                    </Split>
                </Col>
                <Col style={{flex: 1}}>
                    <Split>
                        <span>Zombie</span>
                        <BInput>{!loading && !error && character.class.includes('cleric') ? 13 : '--'}</BInput>
                    </Split>
                    <Split>
                        <span>Ghoul or 2 HD</span>
                        <BInput>{!loading && !error && character.class.includes('cleric') ? 16 : '--'}</BInput>
                    </Split>
                    <Split>
                        <span>Shadow or 3-4 HD</span>
                        <BInput>{!loading && !error && character.class.includes('cleric') ? 19 : '--'}</BInput>
                    </Split>
                    <Split>
                        <span>Wight or 5 HD</span>
                        <BInput style={{marginBottom: '-3px'}}>{!loading && !error && character.class.includes('cleric') ? 20 : '--'}</BInput>
                    </Split>
                </Col>
                <Col style={{flex: 1}}>
                    <Split>
                        <span>Ghast</span>
                        <BInput>--</BInput>
                    </Split>
                    <Split>
                        <span>Wraith or 6 HD</span>
                        <BInput>--</BInput>
                    </Split>
                    <Split>
                        <span>Mummy or 7 HD</span>
                        <BInput>--</BInput>
                    </Split>
                    <Split>
                        <span>Spectre or 8 HD</span>
                        <BInput style={{marginBottom: '-3px'}}>--</BInput>
                    </Split>
                </Col>
                <Col style={{flex: 1}}>
                    <Split>
                        <span>Vampire or 9 HD</span>
                        <BInput>--</BInput>
                    </Split>
                    <Split>
                        <span>Ghost or 10 HD</span>
                        <BInput>--</BInput>
                    </Split>
                    <Split>
                        <span>Lich or 11+ HD</span>
                        <BInput>--</BInput>
                    </Split>
                    <Split>
                        <span>Special</span>
                        <BInput style={{marginBottom: '-3px'}}>--</BInput>
                    </Split>
                </Col>
            </PriestVS>

            <Split style={{border: '3px solid black', marginTop: '-3px'}} className="adnd-reputation-patrons-spells-split">
                <Reputation>
                    <h2>Reputation</h2>
                    <Split>
                        <BLabel style={{width: '4rem', height: '4.25rem'}}></BLabel>
                        <Col style={{marginLeft: '1rem'}}>
                            <Lines style={{ height: '2.25rem' }}>
                                <BLabel style={{border: '0', background: 'white'}}>Title:</BLabel>
                            </Lines>
                            <Lines style={{ height: '2.25rem' }}>
                                <BLabel style={{border: '0', background: 'white'}}>Last Performance:</BLabel>
                            </Lines>
                        </Col>
                    </Split>
                    <Lines style={{ height: '2.25rem' }}>
                        <BLabel style={{border: '0', background: 'white'}}>Earnings:</BLabel>
                    </Lines>
                    <Lines style={{ height: '2.25rem' }}>
                        <BLabel style={{border: '0', background: 'white'}}>Spent on Reputation:</BLabel>
                    </Lines>

                    <div className='adnd-established-reps'>
                        <p>Established Reputations</p>
                        <Split>
                            <BLabel>Index</BLabel>
                            <BLabel>Town/City</BLabel>
                        </Split>
                        <Split>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>
                        <Split>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>
                        <Split>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>
                        <Split>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>
                        <Split>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>
                        <Split>
                            <BInput></BInput>
                            <BInput></BInput>
                        </Split>
                    </div>
                </Reputation>

                <Patrons>
                    <h2>Patrons</h2>
                    <Split>
                        <Split>
                            <BLabel style={{width: '4rem', height: '4.25rem'}}></BLabel>
                            <Col>
                                <BLabel style={{border: 0}}>Current<br />Number</BLabel>
                            </Col>
                        </Split>

                        <Split>
                            <BLabel style={{width: '4rem', height: '4.25rem'}}></BLabel>
                            <Col>
                                <BLabel style={{border: 0}}>Maximum</BLabel>
                            </Col>
                        </Split>
                    </Split>

                    <Split style={{marginTop: '2rem'}}>
                        <Split> <BLabel style={{border: 0}}>Fans</BLabel> <span>1-10</span> </Split>
                        <BInput />
                        <span>attend local</span>
                    </Split>
                </Patrons>
                
                <SpellsPerLevel>
                    <h2>Wizard &amp; Priest Spells</h2>
                    <BLabel style={{border: 0}}>Spells per Level:</BLabel>
                    <Split style={{gap: '1rem'}}>
                        <Split><span style={{flex: '.8'}}>1st</span> <BInput style={{borderLeft: 0, borderTop: 0, borderRight: 0, flex: '.2'}}></BInput></Split>
                        <Split><span style={{flex: '.8'}}>6th</span> <BInput style={{borderLeft: 0, borderTop: 0, borderRight: 0, flex: '.2'}}></BInput></Split>
                    </Split>
                    <Split style={{gap: '1rem'}}>
                        <Split><span style={{flex: '.8'}}>2nd</span> <BInput style={{borderLeft: 0, borderTop: 0, borderRight: 0, flex: '.2'}}></BInput></Split>
                        <Split><span style={{flex: '.8'}}>7th</span> <BInput style={{borderLeft: 0, borderTop: 0, borderRight: 0, flex: '.2'}}></BInput></Split>
                    </Split>
                    <Split style={{gap: '1rem'}}>
                        <Split><span style={{flex: '.8'}}>3rd</span> <BInput style={{borderLeft: 0, borderTop: 0, borderRight: 0, flex: '.2'}}></BInput></Split>
                        <Split><span style={{flex: '.8'}}>8th</span> <BInput style={{borderLeft: 0, borderTop: 0, borderRight: 0, flex: '.2'}}></BInput></Split>
                    </Split>
                    <Split style={{gap: '1rem'}}>
                        <Split><span style={{flex: '.8'}}>4th</span> <BInput style={{borderLeft: 0, borderTop: 0, borderRight: 0, flex: '.2'}}></BInput></Split>
                        <Split><span style={{flex: '.8'}}>9th</span> <BInput style={{borderLeft: 0, borderTop: 0, borderRight: 0, flex: '.2'}}></BInput></Split>
                    </Split>
                    <Split style={{gap: '1rem'}}>
                        <Split><span style={{flex: '.8'}}>5th</span> <BInput style={{borderLeft: 0, borderTop: 0, borderRight: 0, flex: '.2'}}></BInput></Split>
                        <Split><span style={{flex: '.8'}}>Other</span> <BInput style={{borderLeft: 0, borderTop: 0, borderRight: 0, flex: '.2'}}></BInput></Split>
                    </Split>

                    <Lines style={{ marginTop: '2rem' }}>
                        <BLabel style={{border: '0', background: 'white'}}>Spheres Available/Opposition Schools:</BLabel>
                    </Lines>
                </SpellsPerLevel>
            </Split>

            <Henchmen>
                <h1>Henchmen/Animal Companions</h1>
                <Split>
                    <BLabel>Name</BLabel>
                    <BLabel style={{maxWidth: '8rem'}}>Race / Class</BLabel>
                    <BLabel style={{maxWidth: '3rem'}}>AC</BLabel>
                    <BLabel style={{maxWidth: '5rem'}}>HD/Lvl</BLabel>
                    <BLabel style={{maxWidth: '3rem'}}>HP</BLabel>
                    <BLabel style={{maxWidth: '3rem'}}>#AT</BLabel>
                    <BLabel style={{maxWidth: '5rem'}}>THAC0</BLabel>
                    <BLabel style={{maxWidth: '8rem'}}>Damage</BLabel>
                    <BLabel style={{maxWidth: '16rem'}}>Abilities</BLabel>
                </Split>
                <Split>
                    <BInput  />
                    <BInput style={{maxWidth: '8rem'}} />
                    <BInput style={{maxWidth: '3rem'}} />
                    <BInput style={{maxWidth: '5rem'}} />
                    <BInput style={{maxWidth: '3rem'}} />
                    <BInput style={{maxWidth: '3rem'}} />
                    <BInput style={{maxWidth: '5rem'}} />
                    <BInput style={{maxWidth: '8rem'}} />
                    <BInput style={{maxWidth: '16rem'}} />
                </Split>
                <div style={{position: 'relative', marginBottom: '-3px'}}>
                    <BLabel style={{border: 0, position: 'absolute', top: 0, left: 0}}>Notes</BLabel>
                    <BInput style={{width: 'calc(100% + 3px)'}} />
                </div>
                <Split>
                    <BInput  />
                    <BInput style={{maxWidth: '8rem'}} />
                    <BInput style={{maxWidth: '3rem'}} />
                    <BInput style={{maxWidth: '5rem'}} />
                    <BInput style={{maxWidth: '3rem'}} />
                    <BInput style={{maxWidth: '3rem'}} />
                    <BInput style={{maxWidth: '5rem'}} />
                    <BInput style={{maxWidth: '8rem'}} />
                    <BInput style={{maxWidth: '16rem'}} />
                </Split>
                <div style={{position: 'relative', marginBottom: '-3px'}}>
                    <BLabel style={{border: 0, position: 'absolute', top: 0, left: 0}}>Notes</BLabel>
                    <BInput style={{width: 'calc(100% + 3px)'}} />
                </div>
                <Split>
                    <BInput  />
                    <BInput style={{maxWidth: '8rem'}} />
                    <BInput style={{maxWidth: '3rem'}} />
                    <BInput style={{maxWidth: '5rem'}} />
                    <BInput style={{maxWidth: '3rem'}} />
                    <BInput style={{maxWidth: '3rem'}} />
                    <BInput style={{maxWidth: '5rem'}} />
                    <BInput style={{maxWidth: '8rem'}} />
                    <BInput style={{maxWidth: '16rem'}} />
                </Split>
                <div style={{position: 'relative', marginBottom: '-3px'}}>
                    <BLabel style={{border: 0, position: 'absolute', top: 0, left: 0}}>Notes</BLabel>
                    <BInput style={{width: 'calc(100% + 3px)'}} />
                </div>
                <Split>
                    <BInput  />
                    <BInput style={{maxWidth: '8rem'}} />
                    <BInput style={{maxWidth: '3rem'}} />
                    <BInput style={{maxWidth: '5rem'}} />
                    <BInput style={{maxWidth: '3rem'}} />
                    <BInput style={{maxWidth: '3rem'}} />
                    <BInput style={{maxWidth: '5rem'}} />
                    <BInput style={{maxWidth: '8rem'}} />
                    <BInput style={{maxWidth: '16rem'}} />
                </Split>
                <div style={{position: 'relative', marginBottom: '-3px'}}>
                    <BLabel style={{border: 0, position: 'absolute', top: 0, left: 0}}>Notes</BLabel>
                    <BInput style={{width: 'calc(100% + 3px)'}} />
                </div>
                <Split>
                    <BInput  />
                    <BInput style={{maxWidth: '8rem'}} />
                    <BInput style={{maxWidth: '3rem'}} />
                    <BInput style={{maxWidth: '5rem'}} />
                    <BInput style={{maxWidth: '3rem'}} />
                    <BInput style={{maxWidth: '3rem'}} />
                    <BInput style={{maxWidth: '5rem'}} />
                    <BInput style={{maxWidth: '8rem'}} />
                    <BInput style={{maxWidth: '16rem'}} />
                </Split>
                <div style={{position: 'relative', marginBottom: '-3px'}}>
                    <BLabel style={{border: 0, position: 'absolute', top: 0, left: 0}}>Notes</BLabel>
                    <BInput style={{width: 'calc(100% + 3px)'}} />
                </div>
            </Henchmen>

            <MartialArts>
                <h1>Martial Arts</h1>
                <Split>
                    <BLabel>Style</BLabel>
                    <BLabel>#AT</BLabel>
                    <BLabel>AC</BLabel>
                    <BLabel>Principal Attack</BLabel>
                    <BLabel>Hit/Dmg Adj</BLabel>
                    <BLabel>Damage</BLabel>
                    <BLabel>Weapons</BLabel>
                </Split>
                <Split>
                    <BInput />
                    <BInput />
                    <BInput />
                    <BInput />
                    <BInput />
                    <BInput />
                    <BInput />
                </Split>
                <Split>
                    <BInput />
                    <BInput />
                    <BInput />
                    <BInput />
                    <BInput />
                    <BInput />
                    <BInput />
                </Split>
                <Split>
                    <BInput />
                    <BInput />
                    <BInput />
                    <BInput />
                    <BInput />
                    <BInput />
                    <BInput />
                </Split>
                <Split>
                    <BInput />
                    <BInput />
                    <BInput />
                    <BInput />
                    <BInput />
                    <BInput />
                    <BInput />
                </Split>
                <Split>
                    <BInput />
                    <BInput />
                    <BInput />
                    <BInput />
                    <BInput />
                    <BInput />
                    <BInput />
                </Split>
            </MartialArts>

            <br /><br />
            <Lines style={{backgroundPositionY: '27px'}}>
                <BLabel style={{border: '0'}}>Special Maneuvers</BLabel>
            </Lines>

            <Description>
                <h1>Character Description</h1>

                <Split>
                    <Split>
                        <BLabel style={{borderRight: 0, whiteSpace: 'nowrap'}}>Character Name</BLabel>
                        <BInput style={{borderLeft: 0}} />
                    </Split>
                    <Split>
                        <BLabel style={{borderRight: 0, whiteSpace: 'nowrap'}}>Player Name</BLabel>
                        <BInput style={{borderLeft: 0}} />
                    </Split>
                </Split>

                <Split>
                    <Split>
                        <Split>
                            <BLabel style={{borderRight: 0, whiteSpace: 'nowrap'}}>Birth Date</BLabel>
                            <BInput style={{borderLeft: 0}} />
                        </Split>
                        <Split>
                            <BLabel style={{borderRight: 0, whiteSpace: 'nowrap'}}>Birth Rank</BLabel>
                            <BInput style={{borderLeft: 0}} />
                        </Split>
                    </Split>
                    <Split>
                        <Split>
                            <BLabel style={{borderRight: 0, whiteSpace: 'nowrap'}}>Age</BLabel>
                            <BInput style={{borderLeft: 0}} />
                        </Split>
                        <Split>
                            <BLabel style={{borderRight: 0, whiteSpace: 'nowrap'}}>Sex</BLabel>
                            <BInput style={{borderLeft: 0}} />
                        </Split>
                    </Split>
                </Split>

                <Split>
                    <Split>
                        <Split>
                            <BLabel style={{borderRight: 0, whiteSpace: 'nowrap'}}>Alignment</BLabel>
                            <BInput style={{borderLeft: 0}} />
                        </Split>
                        <Split>
                            <BLabel style={{borderRight: 0, whiteSpace: 'nowrap'}}>Deity</BLabel>
                            <BInput style={{borderLeft: 0}} />
                        </Split>
                    </Split>
                    <Split>
                        <Split>
                            <BLabel style={{borderRight: 0, whiteSpace: 'nowrap'}}>Height</BLabel>
                            <BInput style={{borderLeft: 0}} />
                        </Split>
                        <Split>
                            <BLabel style={{borderRight: 0, whiteSpace: 'nowrap'}}>Weight</BLabel>
                            <BInput style={{borderLeft: 0}} />
                        </Split>
                    </Split>
                </Split>

                <Split>
                    <Split>
                        <Split>
                            <BLabel style={{borderRight: 0, whiteSpace: 'nowrap'}}>Race</BLabel>
                            <BInput style={{borderLeft: 0}} />
                        </Split>
                        <Split>
                            <BLabel style={{borderRight: 0, whiteSpace: 'nowrap'}}>Nationality</BLabel>
                            <BInput style={{borderLeft: 0}} />
                        </Split>
                    </Split>
                    <Split>
                        <Split>
                            <BLabel style={{borderRight: 0, whiteSpace: 'nowrap'}}>Hair</BLabel>
                            <BInput style={{borderLeft: 0}} />
                        </Split>
                        <Split>
                            <BLabel style={{borderRight: 0, whiteSpace: 'nowrap'}}>Eyes</BLabel>
                            <BInput style={{borderLeft: 0}} />
                        </Split>
                    </Split>
                </Split>

                <Split style={{width: 'calc(100% + 3px)'}}>
                    <Split style={{flex: 1}}>
                        <Split>
                            <Lines style={{flex: 1,height: '6.2rem', borderLeft: '3px solid black', borderBottom: '3px solid black', backgroundSize: '20px 32px'}}>
                                <BLabel style={{border: 0, whiteSpace: 'nowrap'}}>Racial Abilities</BLabel>
                            </Lines>
                        </Split>
                    </Split>
                    <Col style={{flex: '.6'}}>
                        <Split>
                            <Split>
                                <BLabel style={{borderRight: 0, whiteSpace: 'nowrap'}}>Skin</BLabel>
                                <BInput style={{borderLeft: 0}} />
                            </Split>
                            <Split>
                                <BLabel style={{borderRight: 0, whiteSpace: 'nowrap'}}>Vision</BLabel>
                                <BInput style={{borderLeft: 0}} />
                            </Split>
                        </Split>
                        <Split>
                            <Split>
                                <BLabel style={{borderRight: 0, whiteSpace: 'nowrap'}}>Handedness</BLabel>
                                <BInput style={{borderLeft: 0}} />
                            </Split>
                            <Split>
                                <BLabel style={{borderRight: 0, whiteSpace: 'nowrap'}}>Class</BLabel>
                                <BInput style={{borderLeft: 0}} />
                            </Split>
                        </Split>
                        <Split>
                            <Split>
                                <BLabel style={{borderRight: 0, whiteSpace: 'nowrap'}}>Origin</BLabel>
                                <BInput style={{borderLeft: 0}} />
                            </Split>
                        </Split>
                    </Col>
                </Split>

                <Split style={{marginTop: '2rem'}}>
                    <Col style={{display: 'block', flex: 1}}>
                        <Lines style={{flex: 1, height: '22rem', backgroundPositionY: '28px'}}><BLabel style={{border: 0}}>Personality:</BLabel></Lines>
                        <Lines style={{flex: 1, height: '3rem', backgroundPositionY: '28px'}}><BLabel style={{border: 0}}>Hit Points by Level:</BLabel></Lines>
                    </Col>
                    
                    <div style={{border: '3px solid black', width: '18rem', height: '24rem',  marginLeft: '2rem'}}></div>
                </Split>

                <Split>
                    
                    <div></div>
                </Split>
            </Description>

            

            <br /><br />
            <Lines style={{height: '11in', backgroundPositionY: '27px'}}>
                <BLabel style={{border: '0'}}>Notes</BLabel>
            </Lines>
        </Card>

        <ReactTooltip
            id={tipId}
            effect="solid"
            place="top"
            multiline={true}
        />
    </Container>
}