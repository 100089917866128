const EMagicSchool = {
    GENERAL: "general",
    ALTERATION: "alteration",
    ABJURATION: "abjuration",
    EVOCATION: "evocation",
    CONJURATION: "conjuration",
    NECROMANCY: "necromancy",
    DIVINATION: "divination",
    ENCHANTMENT: "enchantment",
    ILLUSION: "illusion"
}

const GetOpposingSchool = school => {
    switch(school) {
        case EMagicSchool.ALTERATION: return EMagicSchool.ABJURATION
        case EMagicSchool.ABJURATION: return EMagicSchool.ALTERATION

        case EMagicSchool.ILLUSION: return EMagicSchool.NECROMANCY
        case EMagicSchool.NECROMANCY: return EMagicSchool.ILLUSION

        case EMagicSchool.ENCHANTMENT: return EMagicSchool.EVOCATION
        case EMagicSchool.EVOCATION: return EMagicSchool.ENCHANTMENT

        case EMagicSchool.CONJURATION: return EMagicSchool.DIVINATION
        case EMagicSchool.DIVINATION: return EMagicSchool.CONJURATION
    }
}

// classes = "fighter+thief+mage"
const GetForbiddenSchools = classes => {
    const forbidden = []
    if (classes.includes('abjurer')) { forbidden.push(EMagicSchool.ALTERATION) }
    if (classes.includes('conjurer')) { forbidden.push(EMagicSchool.DIVINATION) }
    if (classes.includes('diviner')) { forbidden.push(EMagicSchool.CONJURATION) }
    if (classes.includes('enchanter')) { forbidden.push(EMagicSchool.EVOCATION) }
    if (classes.includes('illusionist')) { forbidden.push(EMagicSchool.NECROMANCY) }
    if (classes.includes('invoker')) { forbidden.push(EMagicSchool.ENCHANTMENT) }
    if (classes.includes('necromancer')) { forbidden.push(EMagicSchool.ILLUSION) }
    if (classes.includes('transmuter')) { forbidden.push(EMagicSchool.ABJURATION) }

    return forbidden
}

export {
    EMagicSchool,
    GetOpposingSchool,
    GetForbiddenSchools
}