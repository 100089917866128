import styled from "styled-components"
import CharacterAvatar from "../CharacterAvatar"
import Button from "../Button"
import PlayerAvatar from '../PlayerAvatar'
import Empty from "../Empty"
import { Card } from "../Card"
import { useState } from "preact/hooks"
import { GrDown, GrMapLocation, GrMenu, GrUp, GrUser, GrUserPolice, GrUserWorker } from "react-icons/gr"
import Scrollbars from "react-custom-scrollbars-2"
import CampaignAvatar from "../CampaignAvatar"

const Container = styled.div`
    padding: 2rem;
    display: flex;
    gap: 2rem;

    & .campaign {
        min-width: 280px;

        label {
            font-weight: 100;
            font-size: 1rem;
        }

        h1 {
            margin: 0 0 1rem 0;
            display: flex;
            
            align-items: center;
        }

        h2 {
            font-weight: 400;
            font-size: 1.33rem;
            margin: 0 0 .5rem 0;
            border-bottom: .5px solid rgba(0,0,0,.2);
        }


    }
`

const SummaryContainer = styled.div`
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;
`

const CharacterSummary = styled.div`
    display: flex;
    flex-direction: column;
    gap: .5rem;
    background: white;
    border-radius: 6px;
    padding: 1.5rem;
    max-width: 28rem;
    flex: 1;
    flex-grow: 0;

    & h1 {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
    

        & span {
            display: flex;
            gap: .5rem;
            
        }

        & button {
            position: absolute;
            top: -3rem;
            right: -2.25rem;
            font-size: 2rem;

            &:hover {
                right: calc(-2.25rem - 2px);
            }

        }
    }
`

const Row = styled.div`
    display: flex;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
`

const StatRow = styled(Row)`
    
    align-items: center;
    flex-direction: column;
    font-size: .75rem;
`

const InfoRow = styled(Row)`
    gap: 2rem;
`

const StatBox = styled.div`
    font-size: 1rem;
    font-weight: bolder;
    border: 2px solid #444;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 1.75rem;
    height: 1.75rem;
`

const StatLabel = styled.div`
    background: #444;
    color: white;
    width: 100%;
    text-align: center;
    padding-top: 3px;
`

const OverviewContainer = styled.div`
    flex: 1;

    & h2 {
        cursor: pointer;
        margin: 0;
        display: flex;
        font-weight: 100;
        border-bottom: .5px solid rgba(0,0,0,0.33);
        margin-bottom: 1rem;

        &:hover {
            font-weight: normal;    
        }

        & svg {
            & path, & polyline {
                stroke: #646464;
            }
        }

    }
`

const AbilityScoreStrategyLabels = {
    DICEROLL_3D6: '3d6 Sequential',
    DICEROLL_3D6_POOL: '3d6 Pool',
    POINT_POOL: 'Point Pool'
}

const HitPointStrategyLabels = {
    HITDIE: 'Hit Die Roll',
    HITDIE_MAX: 'Hit Die Max Value'
}

const SpellSelectionStrategyLabels = {
    FIXED: 'Fixed Based on Class',
    PLAYER: 'Players Choice',
    RANDOM: 'Random'
}

export default function CampaignOverview({campaign, ...props}) {
    const [collapsed, setCollapsed] = useState({
        characters: false,
        online: true,
        maps: true,
    })

    return <Container>

        <Column style={{gap: '.5rem'}}>
            <Card className="campaign">
                <label>Campaign</label>
                <h1><CampaignAvatar campaign={campaign} style={{padding: '5px', boxSizing: 'content-box'}}/> {campaign.name}</h1>

                <Column style={{marginBottom: '.5rem'}}>
                    <div>Ability Score Assignment</div> 
                    <label style={{paddingLeft: '1rem'}}>{AbilityScoreStrategyLabels[campaign.characterFactoryDefaults?.abilityScoreGenerationStrategy]}</label>
                </Column>
                <Column style={{marginBottom: '.5rem'}}>
                    <div>Hit Point Generation</div>
                    <label style={{paddingLeft: '1rem'}}>{HitPointStrategyLabels[campaign.characterFactoryDefaults?.hitPointGenerationStrategy]}</label>
                </Column>
                <Column>
                    <div>Spell Assignment</div>
                    <label style={{paddingLeft: '1rem'}}>{SpellSelectionStrategyLabels[campaign.characterFactoryDefaults?.spellSelectionStrategy]}</label>
                </Column>
            </Card>

            <Button outline>Start Session</Button>
        </Column>

        <OverviewContainer>
            <h2 onClick={() => setCollapsed({...collapsed, characters: !collapsed.characters})}>
                <GrUserWorker />
                &nbsp; Characters 
                <span style={{flex: 1, textAlign: 'right'}}>{collapsed.characters ? <GrDown /> : <GrUp />}</span>
            </h2>
            
            {!collapsed.characters && (!campaign.characters || campaign.characters.length === 0) && <Empty style={{marginTop: '2rem'}} label="No characters in this campaign" />}
            {!collapsed.characters && 
                <SummaryContainer>
                    
                        {campaign.characters.map(character => {
                            const player = campaign.players.find(player => player.id == character.userId)

                            return <CharacterSummary>
                                <h1>
                                    <span>
                                        <div style="position: relative;">
                                            <CharacterAvatar width="2.5rem" height="2.5rem" characterClass={character.class} characterRace={character.race} style={{marginTop: '-.5rem', marginLeft: '-.5rem'}} /> 
                                            <PlayerAvatar {...player} width="1.5rem" height="1.5rem" style={{position: 'absolute', top: '1rem', right: 0}} />
                                        </div>
                                        
                                        <div>
                                            <div style="margin-bottom: .25rem;">{character.name}</div>
                                        </div>
                                    </span>
                                    <Button>…</Button>
                                </h1>
                                <div style="margin-top: -.5rem; font-size: 1rem; font-weight: normal; display: flex; gap: .25rem;">Level {character.level || 1} {character.race} {character.class}</div>
                                <InfoRow>
                                    <div style="display: flex; flex-direction: row;">
                                        <StatRow>
                                            <StatLabel>Str</StatLabel>
                                            <StatBox>{character.str}</StatBox>
                                            <StatLabel style={{paddingBottom: '3px'}}>+0</StatLabel>
                                        </StatRow>
                                        <StatRow style={{marginLeft: '-2px'}}>
                                            <StatLabel>Dex</StatLabel>
                                            <StatBox>{character.dex}</StatBox>
                                            <StatLabel style={{paddingBottom: '3px'}}>+0</StatLabel>
                                        </StatRow>
                                        <StatRow style={{marginLeft: '-2px'}}>
                                            <StatLabel>Con</StatLabel>
                                            <StatBox>{character.con}</StatBox>
                                            <StatLabel style={{paddingBottom: '3px'}}>+0</StatLabel>
                                        </StatRow>
                                        <StatRow style={{marginLeft: '-2px'}}>
                                            <StatLabel>Int</StatLabel>
                                            <StatBox>{character.int}</StatBox>
                                            <StatLabel style={{paddingBottom: '3px'}}>+0</StatLabel>
                                        </StatRow>
                                        <StatRow style={{marginLeft: '-2px'}}>
                                            <StatLabel>Wis</StatLabel>
                                            <StatBox>{character.wis}</StatBox>
                                            <StatLabel style={{paddingBottom: '3px'}}>+0</StatLabel>
                                        </StatRow>
                                        <StatRow style={{marginLeft: '-2px'}}>
                                            <StatLabel>Cha</StatLabel>
                                            <StatBox>{character.cha}</StatBox>
                                            <StatLabel style={{paddingBottom: '3px'}}>+0</StatLabel>
                                        </StatRow>
                                    </div>
                                    <div>
                                        
                                    </div>
                                </InfoRow>
                                
                            </CharacterSummary>
                        })}
                    
                </SummaryContainer>
            }

            <h2 onClick={() => setCollapsed({...collapsed, online: !collapsed.online})}>
                <GrUser />
                &nbsp; Players Online
                <span style={{flex: 1, textAlign: 'right'}}>{collapsed.online ? <GrDown /> : <GrUp />}</span>
            </h2>
            {!collapsed.online && <Empty style={{marginTop: '2rem'}} label="TBD" />}
            {!collapsed.online && 
                <SummaryContainer>
                    
                </SummaryContainer>
            }

            
        </OverviewContainer>
    </Container>
}