import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

const ModalContainer = styled.div`
    width: 66vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
    background: white;
    opacity: ${props => props.visible ? '1' : '0'};
    pointer-events: ${props => props.visible ? 'auto' : 'none'};
    transition: opacity .2s;
    overflow-y: scroll;
    max-height: 90vh;

    padding: 2.5rem;
`

const Lightbox = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,.33);
    z-index: 100;
    opacity: ${props => props.visible ? '1' : '0'};
    pointer-events: ${props => props.visible ? 'auto' : 'none'};
    transition: opacity .2s;
`

function Modal({style = {}, ...props}) {

    const lightboxRef = useRef()
    useEffect(() => {
        lightboxRef.current.addEventListener('click', () => {
            props.onDismiss()
        })
    }, [])

   return (
        <>
        <ModalContainer visible={props.visible} className="adnd-modal" style={style}>
            {props.children}
        </ModalContainer>
        <Lightbox ref={lightboxRef} visible={props.visible} />
        </>
    )
}

export default Modal