import { GrInbox } from "react-icons/gr"
import styled from "styled-components"

const Container = styled.div`
    
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    
    & > svg {
        opacity: .25;
        font-size: 10rem;

        & path {
            stroke: var(--neutral-500);
        }
    }

    & label, & button {
        opacity: .25;
        color: var(--neutral-500);
        font-size: 1rem;
        transition: opacity .25s, border .25s, background .25s !important;
        background: transparent !important;
       
    }


    & button:hover {
        opacity: 1;
        border: .5px solid var(--primary-color);
    }
`

export default function Empty({icon, label, children, style, ...props}) {

    return <Container style={style || {}}>
        {icon ? icon : <GrInbox /> }
        {children}
        <label>{label}</label>
        
    </Container>
}