import styled from "styled-components"
import PlayerAvatar from "../PlayerAvatar"
import Button from "../Button"
import Empty from "../Empty"
import CharacterAvatar from "../CharacterAvatar"
import CharacterSheet from "../CharacterSheet"

const Overview = styled.div`
    display: flex; 
    gap: 4rem;
    padding: 2rem;
`

const InfoGrid = styled.div`
    display: grid;
    grid-template-columns: 176px 1fr;

    & p {
        margin: 0;
    }
`

const List = styled.ul`
    list-style-type: none;
    padding: 0;

    & li {
        display: flex;
        gap: .5rem;
        align-items: center;
        cursor: pointer;

    }
`

export default function PlayerOverview({player, characters, openInNewTab, style = {}, ...props}) {

    return <Overview 
        
        style={style}
    >
        <div style="text-align: center;">
            <PlayerAvatar width="6rem" height="6rem" {...player} />
            <p>{player.name}</p>
            <Button large outline>Open Chat</Button>
        </div>
        
        <div style="margin-top: -1.5rem;">
            <p>{player.name}'s Characters</p>
            <List>
                {characters.map(character => <li onClick={() => openInNewTab('character-' + character.id, character.name, <CharacterSheet characterId={character.id} />)}>
                    <CharacterAvatar characterClass={character.class} characterRace={character.race} />
                    {character.name}
                </li>)}
            </List>
            {characters.length === 0 && <Empty label="No Characters" />}
        </div>
        
        <div>

        </div>
    </Overview>
}