import React from 'react'
import styled from 'styled-components'
import { GrLogout, GrSettingsOption, GrUser } from "react-icons/gr"
import Button from '../Button'
import UserContext from '../../context/UserContext'
import CampaignAvatar from '../CampaignAvatar'
import PlayerAvatar from '../PlayerAvatar'
import { useEffect, useState } from 'preact/hooks'

const Container = styled.div`
   display: flex;
   justify-content: space-between;
   background: var(--invert-bg-color);
   color: var(--invert-text-color);
   height: ${props => props.open ? '46px' : '0'};
   transition: height .25s ease-in;
    align-items: center;
    overflow: hidden;

   & button {
        color: var(--invert-text-color);
   }

   & h1 {
        font-family: 'Fredericka the Great';
        font-weight: 400;
       
        & span {
        font-family: 'Fredericka the Great';
       }
   }

   & .icon path { fill: var(--invert-icon-color); }
`

const Column = styled.div`
   display: flex;
   flex-direction: column;
`

const Flex = styled.div`
   display: flex;
`

const UserActions = styled.div`
   display: flex;

   & svg path {
    stroke: white !important;
   }
`

const signout = () => {
    window.localStorage.clear()
    window.location.href = `${window.location.protocol}//${window.location.host}`
}

export default function MenuBar({campaign, ...props}) {

    const [userMenuOpen, setUserMenuOpen] = useState(false)
    const [path, setPath] = useState(window.location.pathname)

    useEffect(() => {
        setPath(window.location.pathname)
    }, [window.location.pathname])

    return <UserContext.Consumer>
        {({user}) => {
        
        return <>
            <Container open={true}>    
                <Flex style={{alignItems: 'center', marginLeft: '.5rem'}}>
                    {campaign ? <Flex style={{marginLeft: '.5rem', gap: '.25rem', alignItems: 'center'}}>
                            <CampaignAvatar campaign={campaign} />
                            {campaign.name}
                        </Flex> : null}
                </Flex>

                <div>
                    {!user ? 
                        <Button small onClick={props.openLoginModal}>Sign up / in <GrUser className="icon" /></Button> 
                    : 
                        <Button outline onClick={() => setUserMenuOpen(!userMenuOpen)} style={{display: 'flex', gap: '.5rem', position: 'relative', margin: 0}}>
                            <PlayerAvatar icon={user.icon} avatar={user.avatar} color={user.color} /> {user.name}
                        </Button>
                    }   
                    
                </div>
            </Container>
            <Container open={userMenuOpen}>    
                <Flex style={{justifyContent: 'space-between', width: '100%'}}>
                    <UserActions>
                        {path.startsWith('/campaign') && <Button outline style={{display: 'flex', gap: '.5rem'}}><GrSettingsOption /> Campaign Settings</Button>}
                    </UserActions>

                    <UserActions>
                        <Button outline style={{display: 'flex', gap: '.5rem'}}><GrUser /> Account</Button>
                        <Button outline style={{display: 'flex', gap: '.5rem'}} onClick={signout}><GrLogout /> Sign Out</Button> 
                    </UserActions>
                    
                </Flex>
            </Container>
        </>}
    

        }
    </UserContext.Consumer>
}