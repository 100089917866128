import styled from 'styled-components'

const Button = styled.button`
    display: flex;
    background: transparent;
    border: .5px solid transparent;
    padding: .5rem 1.25rem;
    margin: .25rem;
    transition: border .25s;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 200;
    justify-content: center;
    align-items: center;    

    &:hover {
        background: rgba(255, 255, 255, .2);
        font-weight: 400;
    }

    

    ${props => props.outline ? `
        color: white;
        font-weight: 400;
        border: .5px solid rgba(0, 0, 0, .1);
        color: var(--text-color);
        transition: border .25s;

        display: flex;
        align-items: center;

        &:hover {
            border: .5px solid var(--primary-color);
        }

    ` : ''}

    ${props => props.primary ? `
        color: white;
        font-weight: 400;
        background: var(--primary-color);
        display: flex;
        align-items: center;
        height: 2.5rem;

        &:hover {
            font-weight: 400;
            background: var(--primary-color);
        }
    ` : ''}

    ${props => props.tiny ? `
        font-size: .75rem;
        padding: .3rem .5rem;
        
    ` : ''}

    ${props => props.small ? `
        font-size: .825rem;
        height: 2rem;
    ` : ''}

    ${props => props.large ? `
        height: 3rem;
    ` : ''}

    ${props => props.danger ? `
        &:hover {
            border: .5px solid var(--error-color);
        }
    ` : ''}

    ${props => props.disabled ? `
        opacity: .33;
        cursor: not-allowed;
    ` : ''}
`

export default Button