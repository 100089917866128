import { useEffect, useState } from "preact/hooks"
import { GrPrevious } from "react-icons/gr"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import Button from "../Button"
import CampaignList from "../CampaignList"

const Container = styled.aside`
    flex: 1;
    overflow: hidden;
	position: sticky;
    top: 0px;
    
    max-width: ${props => props.collapsed ? '46px' : '280px'};
    min-width: ${props => props.collapsed ? '46px' : '280px'};

    transition: max-width .25s, min-width: .25s, width .25s;


    & button {
        font-size: 1.15rem;
        transition: background .25s;

        &:hover {
            background: rgba(0, 0, 0, .2);
        }
    }

    & .controls {
        display: flex;
        justify-content: space-between;
        height: 46px;
        align-items: center;
    }   

    ${props => props.collapsed ? `
        & .adnd-menu-back > svg {
            transform: rotate(180deg);
        }

        & .controls {
            justify-content: center;
        }
    ` : ''}
`

export default function Sidebar({openLoginModal, setCampaignPanes, selectedCampaign, setSelectedCampaign, ...props}) {
    
    const [collapsed, setCollapsed] = useState(false)

    useEffect(() => {
        window.localStorage.setItem('sidebar-collapsed', collapsed)
    }, [collapsed])

    return <Container className='border-right' collapsed={collapsed}>
        <div className='controls border-bottom'>
            <div></div>
            <Button tiny style={{paddingTop: '1rem', paddingBottom: '1rem'}} className="adnd-menu-back" small onClick={e => {setCollapsed(!collapsed); window.dispatchEvent(new Event("resize"));}}>
                <GrPrevious />
            </Button>
        </div>

        <CampaignList 
            collapsed={collapsed} 
            openLoginModal={openLoginModal} 
            setCampaignPanes={setCampaignPanes} 
            selectedCampaign={selectedCampaign} 
            setSelectedCampaign={setSelectedCampaign} 
        />
    </Container>
} 

