import React from 'react'
import styled from 'styled-components'

const Tag = styled.div`
    background: #8c8c8c;
    color: white;
    border-radius: 4rem;

    padding: 0.1rem 0.8rem;
    margin: 0.2rem;
`

export default props => {
    return <Tag>{props.children} <span onClick={props.onRemove}>🗙</span></Tag>
}