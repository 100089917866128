import { gql } from '@apollo/client'

const LOGIN = gql`
    mutation Login($email: String!, $pswd: String!) {
        login(email: $email, pswd: $pswd) {
            id
            name
            email
            token
            color 
            icon 
            avatar
        }
    }
`

export {
    LOGIN
}