import { gql } from '@apollo/client'

const REGISTER = gql`
    mutation Mutation($name: String!, $email: String!, $pswd: String!) {
        register(name: $name, email: $email, pswd: $pswd) {
            id
            token
            name 
            avatar 
            color 
            icon
        }
    }
`

export {
    REGISTER
}