import React from 'react'
import styled from 'styled-components'

const InpCheckbox = styled.input`
    display: none;
`

const CheckboxContainer = styled.label`
    position: relative;
    width: 1rem;
    height: 1rem;
    display: block;
    pointer-events: none;
    
    &:before {
        display: block;
        content: "";
        position: absolute;
        width: 1rem;
        height: 1rem;
        border: 1px solid ${props => props.checked ? 'var(--primary-color)' : 'black'};
        background: white;
        transition: border .25s;
    }

    &:after {
        position: absolute;
        
        content: "";
        border-bottom: 3px solid var(--primary-color);
        border-right: 3px solid var(--primary-color);
        width: .5rem;
        height: 1rem;
        transform: ${props => props.checked ? 'rotate(45deg) scale(1)' : 'rotate(0deg) scale(0)'};
        right: -0.1rem;
        top: -0.25rem;
        transition: transform .25s;
    }
`

const Label = styled.label`
    padding-left: 1.5rem;
    position: relative; 
    left: -1rem;

    ${props => !props.readonly ? `
        cursor: pointer;
    ` : ''}
    

    &:hover ~ ${CheckboxContainer} {
        &:before {
            border: 1px solid var(--primary-color);
        }
    }
`

const Container = styled.span`

`

function Checkbox({label, setter, style, ...props}) {

   return (
        <div style={{display: 'flex', gap: '.25rem', ...(style ? style : {})}}>
            <InpCheckbox type="checkbox" id={props.id}></InpCheckbox>
            
            <Label for={props.id} onClick={e => setter && setter(!props.value)} style={{order: 1}} readonly={!setter}>{label}</Label>
            <CheckboxContainer for={props.id} style={{...props.style, order: 0}} checked={props.value ? props.value : false}></CheckboxContainer>
        </div>
    )
}

export default Checkbox