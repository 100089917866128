const EClass = {
    FIGHTER: 'fighter',
    PALADIN: 'paladin',
    RANGER: 'ranger',
    CLERIC: 'cleric',
    DRUID: 'druid',
    THIEF: 'thief',
    BARD: 'bard',
    MAGE: 'mage',
    ABJURER: 'abjurer',
    CONJURER: 'conjurer',
    DIVINER: 'diviner',
    ENCHANTER: 'enchanter',
    ILLUSIONIST: 'illusionist',
    INVOKER: 'invoker',
    NECROMANCER: 'necromancer',
    TRANSMUTER: 'transmuter'
}

const EClassGroupLabel = {
    WARRIOR: 'warrior',
    WIZARD: 'wizard',
    PRIEST: 'priest',
    ROGUE: 'rogue'
}

const EClassGroup = {
    [EClassGroupLabel.WARRIOR]: [EClass.FIGHTER, EClass.PALADIN, EClass.RANGER],
    [EClassGroupLabel.WIZARD]: [EClass.MAGE, EClass.ABJURER, EClass.CONJURER, EClass.DIVINER, EClass.ENCHANTER, EClass.ILLUSIONIST, EClass.INVOKER, EClass.NECROMANCER, EClass.TRANSMUTER],
    [EClassGroupLabel.PRIEST]: [EClass.CLERIC, EClass.DRUID],
    [EClassGroupLabel.ROGUE]: [EClass.THIEF, EClass.BARD]
}

const EClassProficiencyGroups = {
    [EClass.FIGHTER]:     ["general", EClassGroupLabel.WARRIOR],
    [EClass.PALADIN]:     ["general", EClassGroupLabel.WARRIOR, EClassGroupLabel.PRIEST],
    [EClass.RANGER]:      ["general", EClassGroupLabel.WARRIOR, EClassGroupLabel.WIZARD],
    [EClass.CLERIC]:      ["general", EClassGroupLabel.PRIEST],
    [EClass.DRUID]:       ["general", EClassGroupLabel.PRIEST, EClassGroupLabel.WARRIOR],
    [EClass.THIEF]:       ["general", EClassGroupLabel.ROGUE],
    [EClass.BARD]:        ["general", EClassGroupLabel.WARRIOR, EClassGroupLabel.WIZARD, EClassGroupLabel.ROGUE],
    [EClass.MAGE]:        ["general", EClassGroupLabel.WIZARD],
    [EClass.ABJURER]:     ["general", EClassGroupLabel.WIZARD],
    [EClass.CONJURER]:    ["general", EClassGroupLabel.WIZARD],
    [EClass.DIVINER]:     ["general", EClassGroupLabel.WIZARD],
    [EClass.ENCHANTER]:   ["general", EClassGroupLabel.WIZARD],
    [EClass.ILLUSIONIST]: ["general", EClassGroupLabel.WIZARD],
    [EClass.INVOKER]:     ["general", EClassGroupLabel.WIZARD],
    [EClass.NECROMANCER]: ["general", EClassGroupLabel.WIZARD],
    [EClass.TRANSMUTER]:  ["general", EClassGroupLabel.WIZARD],
}


const GetClassGroup = _class => {
    return EClassGroup[GetClassGroupLabel(_class)]
}

const GetClassGroupLabel = _class => {
    let c = _class.split('+')[0] // multiclass characters take their init slots from their first class

    let group
    if (EClassGroup[EClassGroupLabel.WARRIOR].includes(c)) {
        group = EClassGroupLabel.WARRIOR            
    } else if (EClassGroup[EClassGroupLabel.WIZARD].includes(c)) {
        group = EClassGroupLabel.WIZARD
    } else if (EClassGroup[EClassGroupLabel.ROGUE].includes(c)) {
        group = EClassGroupLabel.ROGUE
    } else if (EClassGroup[EClassGroupLabel.PRIEST].includes(c)) {
        group = EClassGroupLabel.PRIEST
    }

    return group
}

const GetClassProficiencyGroups = _class => {
    let c = _class.split('+')[0] // multiclass characters take their init slots from their first class
    return EClassProficiencyGroups[c]
}

export {
    EClass,
    EClassGroup,
    EClassGroupLabel,
    EClassProficiencyGroups,
    GetClassGroup,
    GetClassGroupLabel,
    GetClassProficiencyGroups
}