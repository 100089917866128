import styled from "styled-components"
import { GiAllSeeingEye, GiArcher, GiDeer, GiEmbrassedEnergy, GiPanFlute, GiPrayer, GiRaiseZombie, GiRobber, GiRobe, GiRogue, GiRosaShield, GiShadowFollower, GiSparkSpirit, GiSpiralTentacle, GiSplitBody, GiSwordwoman, GiTemplarShield, GiWingedShield } from 'react-icons/gi'
import { GrCircleQuestion } from "react-icons/gr"

const Avatar = styled.div`
    width: ${props => props.height};
    height: ${props => props.height};
    border-radius: 50%;
    border: .25rem solid var(--${props => props.color});
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    ${props => props.avatar ? `
        background: url(${props.avatar});
    ` : null}

    & svg {
        font-size: 3rem;
    }

    & svg > path {
        stroke: white;
        fill: var(--${props => props.color});
    }
`

const Icons = {
    "fighter": <GiSwordwoman />,
    "ranger": <GiArcher />,
    "paladin": <GiEmbrassedEnergy />,
    "cleric": <GiPrayer />,
    "druid": <GiDeer />,
    "bard": <GiPanFlute />,
    "thief": <GiRogue />,
    "mage": <GiRobe />,
    "abjurer": <GiWingedShield />,
    "conjurer": <GiSplitBody />,
    "diviner": <GiAllSeeingEye />,
    "enchanter": <GiRosaShield />,
    "illusionist": <GiShadowFollower />,
    "invoker": <GiSparkSpirit />,
    "transmuter": <GiSpiralTentacle />,
    "necromancer": <GiRaiseZombie />,
    "pending": <GrCircleQuestion />
}

const Colors = {
    "human": "purple-500",
    "dwarf": "gold-500",
    'elf': "pink-500",
    "half-elf": "light-pink-500",
    'gnome': "green-500",
    'halfling': "brown-500",
    'pending': 'neutral-500'
}

export default function CharacterAvatar({characterClass, characterRace, avatar, width, height, style, ...props}) {
    return <Avatar 
        color={Colors[characterRace]} 
        avatar={avatar}
        width={width ? width : '2rem'}
        height={height ? height : '2rem'}
        style={style || {}}
    >{Icons[characterClass]}</Avatar>
}