import React from 'react'
import styled from 'styled-components'
import Modal from '../Modal'
import LabeledInput from '../LabeledInput'
import Checkbox from '../Checkbox'
import Button from '../Button'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { LOGIN } from './mutations'
import { useState } from 'preact/hooks'
import UserContext from '../../context/UserContext'
import { toast } from 'react-toastify'
import { GET_CAMPAIGNS } from '../CampaignList/queries'

const Login = styled.div`
   & > .adnd-modal {
        max-width: 640px;

        & > h1 {
            margin: 0 0 2.5rem 0;

            & span {
                font-size: 1rem;
                font-weight: 200;
            }
        }
   }
`

const Column = styled.div`
   display: flex;
   flex-direction: column;
   gap: .5rem;
`

const Flex = styled.div`
   display: flex;
`

export default function LoginModal({visible, setVisible, openRegisterModal, redirectTo, setRedirectTo}) {

    const [login, { loading, error }] = useMutation(LOGIN)
    const [email, setEmail] = useState("")
    const [rememberMe, setRememberMe] = useState(false)
    const [password, setPassword] = useState("")

    const nav = useNavigate()

    return <Login>
        <UserContext.Consumer>
            {({user, setUser}) => <Modal visible={visible} onDismiss={e => setVisible(false)}>
                <h1>Login <span>No account? <span href="#" onClick={openRegisterModal} style="cursor: pointer; font-weight: 400; color: var(--primary-color);">Click here to register</span></span></h1>

                <Column>
                    <LabeledInput label="Email" value={email} setValue={setEmail} />
                    <LabeledInput label="Password" value={password} setValue={setPassword} type="password" />
                    <Flex style={{position: 'relative', top: '-.75rem', justifyContent: 'space-between'}}>
                        <Checkbox id="adnd-remember-me" value={rememberMe} setter={setRememberMe} label="Remember me" />
                        <Link to="/forgot-password" style={{textDecoration: 'none', color: 'var(--primary-color)'}}>Forgot your password?</Link>
                    </Flex>

                    <Flex>
                        <div style={{flex: '1'}}></div>
                        <Button 
                            primary 
                            large 
                            disabled={loading}
                            onClick={e => !loading && login({
                                variables: {
                                    email: email,
                                    pswd: password
                                },
                                onError: (error) => {
                                    console.error(error)
                                    toast.error(`An unexpected error has occurred, please try again.`, {autoClose: false})
                                },
                                onCompleted: ({login}) => {
                                    if (login) {
                                        setUser(login)
                                        
                                        window.localStorage.setItem('authedUser', JSON.stringify(login)) 

                                        if (rememberMe) { 
                                            window.localStorage.setItem('rememberMe', true)  
                                        }

                                        if (redirectTo) { 
                                            nav(redirectTo) 
                                            setRedirectTo(undefined)
                                        }

                                        setVisible(false)

                                        window.location = window.location
                                    }
                                }
                            })}
                        >{loading ? <img src="/assets/img/loader-white.svg" width="36" height="46" /> : 'Log in'}</Button>
                    </Flex>
                </Column>

                
            </Modal>}
        </UserContext.Consumer>
        
    </Login>
}