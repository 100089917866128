import styled from 'styled-components'

const InputContainer = styled.div`
    position: relative;
    width: 100%;

    & > label {
        position: absolute;
        left: .5rem;
        top: 50%;
        transform: translateY(calc(-50% - .9rem));
        transition: transform .25s, left .25s;
        pointer-events: none;
    }

    & > input {

        width: 100%;
        margin: 0 0 2rem 0;
        padding: 0.5rem 1rem;
        border: 0.5px solid ${props => props.error ? 'var(--error-color)' : 'rgba(0,0,0,.4)'};
        border-radius: 6px;
        font-size: 1.25rem;
        font-weight: 400;

        &:not(:placeholder-shown) ~ label {
            transform: translateY(calc(-50% - 3.15rem));
            left: 0;
        }   

        &:focus {
            border-color: var(--primary-color);
        }
    }
`

const Error = styled.div`
    color: var(--red-500);
    position: absolute;
    bottom: .5rem;
    right: 0;
`

export default function LabeledInput({label, value, type, setValue, style, error}) {

    return <InputContainer error={!!error}>
        <input type={type ? type : 'text'}  value={value} onChange={e => setValue(e.target.value)} placeholder="&nbsp;" style={style ? style : {}} />
        <label>{label}</label>
        {error ? <Error>{error}</Error> : null}
    </InputContainer>
}