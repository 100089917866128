import styled from 'styled-components'

const Input = styled.input`
    border: .5px solid rgba(0, 0, 0, .4); 
    padding: .5rem;

    &[type="number"] {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
        text-align: center;
        height: 2rem;
    }
`

export default Input