import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'

import {
	BrowserRouter as Router,
	Routes,
	Route,
  } from "react-router-dom";

import { ApolloClient, ApolloProvider, createHttpLink, split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { setContext } from '@apollo/client/link/context'
import cache from '../cache'

import Home from '../routes/home'
import LoginModal from './LoginModal'
import RegisterModal from './RegisterModal'
import MenuBar from './MenuBar'
import styled from 'styled-components'
import Button from './Button'
import { GrPrevious } from 'react-icons/gr'
import CampaignList from './CampaignList'
import Sidebar from './Sidebar'
import NewCampaign from '../routes/newcampaign'
import UserContext from '../context/UserContext'
import Campaign from '../routes/campaign'
import Invite from '../routes/invite'
import NewCharacter from '../routes/newcharacter'
import InitializeUser from '../routes/initializeuser'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Container = styled.div`
    display: flex;
	height: calc(100vh - 46px);
	overflow: hidden;
  	
    & > main {
        flex: 1;

        & > .adnd-card {
            max-width: 1300px;
            margin: .5rem;
        }
    }

    & .adnd-card .adnd-card {
		border: 1px solid rgba(0,0,0,.1);


		&.active {
			border: .5px solid rgba(32, 117, 214, .5);
			box-shadow: 0 0 4px rgba(0, 0, 0, .033);

			&:hover {
				border: .5px solid rgba(32, 117, 214, .5);
				box-shadow: 0 0 4px rgba(0, 0, 0, .033);
			}
		}

		&:hover {
			border: 1px solid rgba(0,0,0,.16);
			box-shadow: 0 0 4px rgba(0, 0, 0, .033);
		}
	}

    & .border-right {
        border-right: .5px solid rgba(0, 0, 0, .1);
    }

    & .border-bottom {
        border-bottom: .5px solid rgba(0, 0, 0, .1);
    }
`

const wsLink = new GraphQLWsLink(createClient({
	url: 'wss://api.campaigns.synaxis.systems',
	//url: 'wss://localhost:4000/graphql'
}))

const httpLink = createHttpLink({
	uri: 'https://api.campaigns.synaxis.systems',
	//uri: 'https://localhost:4000/graphql'
})

const authLink = setContext((_, { headers }) => {

	let json = localStorage.getItem('authedUser')

	let token
	if (json) {
		json = JSON.parse(json)
		token = json.token
	} else {
		token = localStorage.getItem('token')
	}
	
	if(token) {
		token = token.replace(/\"/g, "")
	}

	return {
	  headers: {
		...headers,
		authorization: token ? `${token}` : "",
	  }
	}
})

const httpLinkChain = authLink.concat(httpLink)

const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query)
		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		)
	},
	wsLink,
	httpLinkChain
)

const client = new ApolloClient({
	cache,
	link: splitLink
})

const exludePaths = [
	"/invite/",
	"/new-character/",
	"/user/initialize"
]

const App = () => {

	const [loginModalVisible, setLoginModalVisible] = useState(false)
	const [registerModalVisible, setRegisterModalVisible] = useState(false)
	const [onLoginRedirectTo, setOnLoginRedirectTo] = useState()
	const [user, setUser] = useState()
	const [campaignPanes, setCampaignPanes] = useState([ [] ])
	const [selectedCampaign, setSelectedCampaign] = useState()

	const openLoginModal = (loginRedirect) => {
		setRegisterModalVisible(false)
		setLoginModalVisible(true)
		setOnLoginRedirectTo(loginRedirect)
	}

	useEffect(() => {
		const json = window.localStorage.getItem('authedUser')
		if (json) {
			const user = JSON.parse(json)
			setUser(user)
		}
	}, [])

	return <div id="app" style={{position: 'relative'}}>
		<UserContext.Provider value={{user, setUser}}>
			<ApolloProvider client={client}>

				<MenuBar campaign={selectedCampaign} openLoginModal={openLoginModal} />
				<Router>
					<Container>
						{exludePaths.find(path => window.location.pathname.includes(path)) ? null : 
							<Sidebar 
								openLoginModal={openLoginModal} 
								setCampaignPanes={setCampaignPanes} 
								selectedCampaign={selectedCampaign} 
								setSelectedCampaign={setSelectedCampaign} 
							/>
						}

						<main>
							<Routes>
								<Route path="/" element={<Home />} />
								<Route path="/user/initialize" element={<InitializeUser user={user} setUser={setUser} />} />
								<Route path="/new-campaign" element={<NewCampaign />} />
								<Route path="/campaign/:campaignSlug/invite/:invitationSlug" element={<Invite user={user} />} />
								<Route path="/campaign/:campaignSlug/new-character/:factorySlug" element={<NewCharacter user={user} />} />
								<Route path="/campaign/:campaignSlug/*" element={<Campaign campaignPanes={campaignPanes} setCampaignPanes={setCampaignPanes} campaign={selectedCampaign} />} />
							</Routes>
						</main>

						<LoginModal 
							visible={loginModalVisible} 
							setVisible={setLoginModalVisible} 
							redirectTo={onLoginRedirectTo}
							setRedirectTo={setOnLoginRedirectTo}
							openRegisterModal={() => {
								setLoginModalVisible(false)
								setRegisterModalVisible(true)
							}} 
						/>
						<RegisterModal 
							visible={registerModalVisible} 
							setVisible={setRegisterModalVisible} 
							openLoginModal={openLoginModal} 
						/>
					</Container>
				</Router>
				
			</ApolloProvider>
		</UserContext.Provider>

		<ToastContainer />
	</div>
}

export default App
