import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Card } from '../Card'

const ContextMenu = styled.div`
    position: absolute;
    top: ${props => props.top}px;
    left: ${props => props.left}px;
    width: 12rem;
    opacity: ${props => props.visible ? '1' : '0'};
    pointer-events: ${props => props.visible ? 'auto' : 'none'};
    z-index: 201;
`

const ClickContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: ${props => props.visible ? '1' : '0'};
    pointer-events: ${props => props.visible ? 'auto' : 'none'};
    z-index: 200;
`

export default props => {

    const clickContainerRef = useRef(null)
    useEffect(() => {
        clickContainerRef.current.addEventListener('click', event => {
            props.onDismiss(event)
        })
    }, [])

    const [top, setTop] = React.useState(props.top)
    const [left, setLeft] = React.useState(props.left)

    useEffect(() => {
        setTop(props.top)
        setLeft(props.left)
    }, [props.top, props.left])

    return <>
        <ContextMenu visible={props.visible} top={top} left={left}>
            <Card>
                {props.children}
            </Card>
        </ContextMenu>
        <ClickContainer visible={props.visible} ref={clickContainerRef} />
    </>
}