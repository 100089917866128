import styled from "styled-components"

import { GrAccessibility, GrAdd, GrAnchor, GrAttraction, GrBike, GrBook, GrBug, GrCar, GrCircleInformation, GrClearOption, GrCloud, GrCluster, GrCodeSandbox, GrGremlin, GrGrow, GrPaint, GrRestaurant, GrRobot, GrAddCircle, GrNext, GrFormPreviousLink, GrUserAdd, GrSettingsOption, GrUserWorker, GrShare, GrSplit, GrDocumentText, GrUser, GrExpand, GrClose, GrCaretDown, GrCaretUp, GrDown, GrUp, GrLink, GrMapLocation, GrAction, GrMenu } from 'react-icons/gr'
import Button from "../Button"
import { useQuery, useSubscription } from "@apollo/client"
import { GET_CAMPAIGN } from "./queries"
import CharacterList from "../CharacterList"

import PlayerAvatar from "../PlayerAvatar"
import Scrollbars from "react-custom-scrollbars-2"
import CharacterAvatar from "../CharacterAvatar"
import { CHARACTER_ADDED, PLAYER_SUBSCRIPTION, PLAYER_UPDATED } from "./subscriptions"
import { useEffect, useState } from "preact/hooks"
import { lazy, Suspense } from "preact/compat"
import Shimmer from "../Shimmer"
import Empty from "../Empty"
import UserContext from "../../context/UserContext"
import PlayerOverview from "../PlayerOverview"
import MapEditor from "../../routes/mapeditor"
import BehaviourEditor from "../../routes/behavioureditor"

const InvitePlayers = lazy(() => import('../../routes/inviteplayers')) 
const CreateCharacters = lazy(() => import('../../routes/createcharacters'))
const CharacterSheet = lazy(() => import('../../components/CharacterSheet'))

const CampaignColor = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 6px;
    background: var(--${props => props.color});
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .5rem;
`

const Flex = styled.div`
    display: flex;
`

const EmptyContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
`

const Container = styled.div`
    height: calc(100% - 7rem);
    display: flex;
    flex-direction: column;

    & ul {
        height: 100%;
        display: block;
        padding-left: 0 !important;

        &.adnd-scroll-list {
            padding-left: .25rem !important;     
        }

        & li {
            padding-right: .5rem !important;
            padding-left: .9rem !important;

            & button {
                margin: 0;
            }
        }

        ${props => props.collapsed ? `
            & li {
                padding-left: 0 !important;
            }
        ` : ''}
    }

    ${props => props.collapsed ? `
        & .adnd-campaign-menu {
            
        }

        & ${EmptyContainer} {
            display: none;
        }
    ` : ''}
`



export default function AdminMenu({openInNewTab, openInNewPane, campaignId, collapsed, ...props}) {

    const {loading, error, data, subscribeToMore} = useQuery(GET_CAMPAIGN, {
        variables: {
            campaignId
        }
    })
    const [actionsCollapsed, setActionsCollapsed] = useState(false)
    const [sheetsCollapsed, setSheetsCollapsed] = useState(false)
    const [playersCollapsed, setPlayersCollapsed] = useState(false)
    const [mapsCollapsed, setMapsCollapsed] = useState(true)
    const [behavioursCollapsed, setBehavioursCollapsed] = useState(true)

    useEffect(() => {
        subscribeToMore({
            document: PLAYER_UPDATED,
            variables: {campaignId},
            updateQuery(prev, {subscriptionData}) {
                if (!subscriptionData.data) return prev 
                const updatedPlayer = subscriptionData.data.playerUpdated 
                const players = [...prev.campaign.players]
                const index = players.findIndex(p => p.id == updatedPlayer.id)
                if (index !== -1) {
                    players[index] = {
                        ...players[index],
                        ...updatedPlayer
                    }
                } else {
                    players.push(updatedPlayer)
                }

                return {
                    campaign: {
                        ...prev.campaign,
                        players
                    }
                }
            }
        })

        subscribeToMore({
            document: CHARACTER_ADDED,
            variables: {campaignId},
            updateQuery(prev, {subscriptionData}) {
                if (!subscriptionData.data) return prev 
                const newChar = subscriptionData.data.characterAdded 
                const characters = [...prev.campaign.characters, newChar]

                return {
                    campaign: {
                        ...prev.campaign,
                        characters
                    }
                }
                
            }
        })
    }, [])

    return <Container collapsed={collapsed}>
        <UserContext.Consumer>
            {userContext => <>
                <section style={{minHeight: '40px',}}>
                    <h1 onClick={() => setActionsCollapsed(!actionsCollapsed)} style={{padding: '.5rem 0', cursor: 'pointer',  justifyContent: collapsed ? 'center' : 'unset'}}><GrMenu />{!collapsed ? <>&nbsp; DM Actions</> : null} <span style={{flex: 1, textAlign: 'right'}}>{actionsCollapsed ? <GrDown /> : <GrUp />}</span></h1>
                    <ul className="adnd-campaign-menu" style={{display: actionsCollapsed ? 'none' : 'block'}}>
                        <li data-for="adnd-campaign-menu-tooltip" data-tip={collapsed ? "Invite Players" : null} onClick={e => {
                            e.stopPropagation()
                            openInNewTab("add-players", "Invite Player(s)", <InvitePlayers campaignId={campaignId} />, e => e.stopPropagation(), e => e.stopPropagation())
                        }}>
                            <div style={{display: 'flex', justifyContent: 'space-between', flex: 1, alignItems: 'center'}}>
                                <div style={{display: 'flex', alignItems: 'center', flex: 1}}>
                                    <CampaignColor color={'primary-color'}><GrUserAdd /></CampaignColor>
                                    {!collapsed ? 'Invite Players' : ''}
                                    <div style={{display: 'flex', flex: 1, justifyContent: 'space-between'}}>
                                        <div></div>
                                        <Button 
                                            tiny
                                            onClick={e => {
                                                e.stopPropagation()
                                                openInNewPane('add-players', "Invite Players", <InvitePlayers campaignId={campaignId} />)
                                            }}
                                        ><GrSplit className='dark-icon' /></Button>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li data-for="adnd-campaign-menu-tooltip" data-tip={collapsed ? "Create Characters" : null} onClick={e => {
                            e.stopPropagation()
                            
                            openInNewTab("create-characters", "Create Characters", <CreateCharacters campaign={data?.campaign} user={userContext.user}  />, e => e.stopPropagation(), e => e.stopPropagation())
                        }}>
                            <div style={{display: 'flex', justifyContent: 'space-between', flex: 1, alignItems: 'center'}}>
                                <div style={{display: 'flex', flex: 1, alignItems: 'center'}}>
                                    <CampaignColor color={'primary-color'}><GrUserWorker /></CampaignColor>
                                    {!collapsed ? 'Create Characters' : ''}
                                    <div style={{display: 'flex', flex: 1, justifyContent: 'space-between'}}>
                                        <div></div>
                                        <Button 
                                            tiny
                                            onClick={e => {
                                                e.stopPropagation()
                                                openInNewPane('create-characters', "Create Characters", <CreateCharacters />)
                                            }}
                                        ><GrSplit className='dark-icon' /></Button>
                                    </div>
                                </div>
                            </div>
                        </li>


                        <li data-for="adnd-campaign-menu-tooltip" data-tip={collapsed ? "Create NPC Behaviour" : null} onClick={e => {
                            e.stopPropagation()
                            //openInNewTab("create-npc-behaviour", "Create NPC Behaviour", <BehaviourEditor   />, e => e.stopPropagation(), e => e.stopPropagation())
                            window.open("https://engine.synaxis.systems/tools/nodeeditor", '_blank').focus()
                        }}>
                            <div style={{display: 'flex', justifyContent: 'space-between', flex: 1, alignItems: 'center'}}>
                                <div style={{display: 'flex', flex: 1, alignItems: 'center'}}>
                                    <CampaignColor color={'primary-color'}><GrAction /></CampaignColor>
                                    {!collapsed ? 'Create NPC Behaviour' : ''}
                                    
                                    <div style={{display: 'flex', flex: 1, justifyContent: 'space-between'}}>
                                        <div></div>
                                        <GrLink className='dark-icon' />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </section>

                <section style={{flex: 1,minHeight: '40px', position: 'relative', overflow: 'hidden', flex: sheetsCollapsed ? '.05' : '1'}}>
                    <h1 onClick={() => setSheetsCollapsed(!sheetsCollapsed)} style={{padding: '.5rem 0', cursor: 'pointer',  justifyContent: collapsed ? 'center' : 'unset'}}><GrDocumentText />{!collapsed ? <>&nbsp; Character Sheets</> : null} <span style={{flex: 1, textAlign: 'right'}}>{sheetsCollapsed ? <GrDown /> : <GrUp />}</span></h1>
                    {loading ? <ul>
                        <li style={{display: 'flex', gap: '.5rem'}}>
                            <Flex style={{gap: '.5rem'}}>
                                <Shimmer circle width="2rem" height="2rem" />
                                <Shimmer width="8rem" height="1rem" />
                            </Flex>
                        </li>

                        <li style={{display: 'flex', gap: '.5rem'}}>
                            <Flex style={{gap: '.5rem'}}>
                                <Shimmer circle width="2rem" height="2rem" />
                                <Shimmer width="4rem" height="1rem" />
                            </Flex>
                        </li>
                    </ul> : null}
                    <Scrollbars style={{height: '100%', display: sheetsCollapsed ? 'none' : 'block'}} renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}>
                        {data && data.campaign && data.campaign.characters && data.campaign.characters.length > 0 ? <ul className="adnd-scroll-list" style={{display: sheetsCollapsed ? 'none' : 'block'}}>
                                {data.campaign.characters.map(character => <li data-for="adnd-campaign-menu-tooltip" data-tip={collapsed ? character.name + "<br />" + character.race + " " + character.class : null} style={{display: 'flex', gap: '.5rem'}} onClick={e => openInNewTab("character-" + character.id, character.name, <CharacterSheet characterId={character.id} />, e => e.stopPropagation(), e => e.stopPropagation())}>
                                    <CharacterAvatar characterClass={character["class"]} characterRace={character.race} />
                                    {!collapsed ? character.name : ''}

                                    <div style={{display: 'flex', flex: 1, justifyContent: 'space-between'}}>
                                        <div></div>
                                        <Button 
                                            tiny
                                            style={{margin: '-.25rem .25rem -.25rem 0'}}
                                            onClick={e => {
                                                e.stopPropagation()
                                                openInNewPane('character-' + character.id, character.name, <CharacterSheet characterId={character.id} />)
                                            }}
                                        ><GrSplit className='dark-icon' /></Button>
                                    </div>
                                </li>)}
                        </ul> : null}
                    </Scrollbars>
                    {data && data.campaign && data.campaign.characters && data.campaign.characters.length === 0 && !sheetsCollapsed? <EmptyContainer><Empty icon={<span></span>} label="No character sheets" /></EmptyContainer> : null}
                </section>

                <section style={{flex: 1, minHeight: '40px', position: 'relative', overflow: 'hidden', flex: playersCollapsed ? '.05' : '1'}}>
                    <h1 onClick={() => setPlayersCollapsed(!playersCollapsed)} style={{padding: '.5rem 0', cursor: 'pointer', justifyContent: collapsed ? 'center' : 'unset'}}><GrUser />{!collapsed ? <>&nbsp; Players</> : null} <span style={{flex: 1, textAlign: 'right'}}>{playersCollapsed ? <GrDown /> : <GrUp />}</span></h1>
                    {loading ? <ul>
                        <li style={{display: 'flex', gap: '.5rem'}}>
                            <Flex style={{gap: '.5rem'}}>
                                <Shimmer circle width="2rem" height="2rem" />
                                <Shimmer width="5rem" height="1rem" />
                            </Flex>
                        </li>

                        <li style={{display: 'flex', gap: '.5rem'}}>
                            <Flex style={{gap: '.5rem'}}>
                                <Shimmer circle width="2rem" height="2rem" />
                                <Shimmer width="7rem" height="1rem" />
                            </Flex>
                        </li>
                    </ul> : null}
                    <Scrollbars style={{height: '100%'}} renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}>
                    {data && data.campaign && data.campaign && data.campaign.players && data.campaign.players.length > 0 ? <ul className="adnd-scroll-list" style={{display: playersCollapsed ? 'none' : 'block'}}>
                        {data.campaign.players.filter(player => player.name).map(player => <li 
                            style={{display: 'flex', gap:  '.5rem'}} 
                            data-for="adnd-campaign-menu-tooltip" 
                            data-tip={collapsed ? player.name : null}
                            onClick={e => openInNewTab("player-" + player.id, player.name, <PlayerOverview openInNewTab={openInNewTab} player={player} characters={data.campaign.characters.filter(character => character.userId == player.id)} />, e => e.stopPropagation(), e => e.stopPropagation())}
                        >
                            <PlayerAvatar color={player.color} icon={player.icon} />
                            {!collapsed ? player.name : ''}
                        </li>)}
                    </ul> : null}
                    </Scrollbars>
                    {data && data.campaign && data.campaign.players && data.campaign.players.length === 0 && !playersCollapsed ? <EmptyContainer>
                        <Empty icon={<span></span>} label="No players" />
                    </EmptyContainer> : null}
                </section>

                <section style={{flex: 1,minHeight: '40px', position: 'relative', overflow: 'hidden', flex: mapsCollapsed ? '.05' : '1'}}>
                    <h1 onClick={() => setMapsCollapsed(!mapsCollapsed)} style={{padding: '.5rem 0', cursor: 'pointer', justifyContent: mapsCollapsed ? 'center' : 'unset'}}><GrUser />{!collapsed ? <>&nbsp; Maps</> : null} <span style={{flex: 1, textAlign: 'right'}}>{mapsCollapsed ? <GrDown /> : <GrUp />}</span></h1>
                    {loading ? <ul>
                        <li style={{display: 'flex', gap: '.5rem'}}>
                            <Flex style={{gap: '.5rem'}}>
                                <Shimmer circle width="2rem" height="2rem" />
                                <Shimmer width="5rem" height="1rem" />
                            </Flex>
                        </li>

                        <li style={{display: 'flex', gap: '.5rem'}}>
                            <Flex style={{gap: '.5rem'}}>
                                <Shimmer circle width="2rem" height="2rem" />
                                <Shimmer width="7rem" height="1rem" />
                            </Flex>
                        </li>
                    </ul> : null}
                    
                    <EmptyContainer style={{display: mapsCollapsed ? 'none' : 'block'}}>
                        <Empty icon={<span></span>} label="No maps" />
                    </EmptyContainer>
                </section>
            </>}
        </UserContext.Consumer>
        
    </Container>
}